.sTtrackContentsWrap {
  padding-top: 1em;
}
.sTtrackContents1 {
  margin-bottom: 1em;
  padding: 0 0.25em;
  display: flex;
  justify-content: center;
}
.sTtrackContents1b {
  overflow-x: auto;
  overflow-y: hidden;

  /* display: grid;
  grid-auto-flow: column;
  column-gap: 1vw; */
  display: flex;
  padding: 1vh 1vw;
  margin-right: 1vw;
  justify-content: flex-start;
}
.sTtrackContents2 {
  margin: 0 0.5em;
  padding-bottom: 0.5em;
}

.sTtrackOuterWrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  user-select: none;
}
.sTtrackHeader {
  margin-left: 2.205em;
  display: flex;
  flex-basis: 2.25em;
  flex-shrink: 0;
  flex-grow: 0;
  visibility: hidden;
  z-index: -100;
}

.sTtrackItem1 {
  margin: 0 0.25em;
  padding: 0.25em;
  height: 100%;
  min-width: 12em;
  border-radius: 5px;
}
.sTtrackSmallCardContents {
  padding: 0.25em 0.25em 0 0.25em;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-around;
  overflow: hidden;
}
.sTtrackIconWrap {
  display: flex;
  align-items: center;
  text-align: left;
}
.sTtrackCategoryText {
  width: 100%;
  text-align: left;
}

.sTtrackContents2_so {
  overflow-x: hidden;
  overflow-y: auto;

  margin: 0 auto;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
}
.sTtrackCardWrap {
  /* width: 100%; */
  border-radius: 5px;
  margin: 0.25em 0.25em 1em 0.25em;
}
.sTtrackCardHeader {
  width: 100%;
  padding: 0.25em;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sTtrackDetails {
  width: 100%;
  padding: 0.25em;
  text-align: left;
}
.sTtrackFooterWrap {
  flex-basis: 3em; /*ALIGNED WITH FOOTER IN GLOBAL*/
  flex-shrink: 0;
  flex-grow: 0;
}
.sTtrackLoadingSummary {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.trackOrderCard {
  display: grid;
  grid-template-columns: 1fr;
}
@media (min-width: 768px) {
  .sTtrackHeader {
    margin-left: 0;
  }
  .trackOrderCard {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 768px) and (orientation: landscape) {
  .sTtrackCardWrap {
    margin: 0em 0.25em 1em 0.25em;
  }
}
.sTtrack_anim_fadeIn_0300 {
  animation: sTtrackFadeIn 0.3s linear 0.3s 1 normal forwards;
}
.sTtrack_anim_fadeIn_0310 {
  animation: sTtrackFadeIn 0.3s linear 1s 1 normal forwards;
}
@keyframes sTtrackFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
