.button1 {
  /* display: inline-block; */
  padding: 1em;
  border: 0.1em solid #ffffff;
  /* margin: 0 0.3em; */
  /* border-radius: 0.12em; */
  /* box-sizing: border-box; */
  /* text-decoration: none; */
  /* font-family: "Roboto", sans-serif; */
  /* font-weight: 300; */
  /* color: white; */
  text-align: center;
  transition: all 0.2s;
  background-color: transparent;
  /* border-radius: 5px; */
}
.button1:hover {
  /* color: #000000; */
  /* background-color: ghostwhite; */
  text-decoration: underline;
}

.anim_background {
  animation: animBackground 10s ease 0s infinite normal forwards;
}

@keyframes animBackground {
  9% {
    transform: none;
    background-color: rgba(0, 0, 0, 0.12);
  }
  12% {
    transform: scale(1.05);
    background-color: rgba(0, 0, 0, 0.2);
  }
  16% {
    transform: scale(0.95);
    background-color: rgba(0, 0, 0, 0.16);
  }
  20% {
    transform: scale(1.03);
    background-color: rgba(0, 0, 0, 0.22);
  }
  24% {
    transform: scale(1);
    background-color: rgba(0, 0, 0, 0.18);
  }
}
