/* REVIEWED APR 2021 */

.sTstoreHeaderOuter {
  top: 0;
  width: 100%;
  z-index: 100;
  user-select: none;
}

.searchResultItem:hover {
  filter: brightness(1.3);
}
