/* .flag{
  color: black;
}

.flag.flag-dropdown.open {
  z-index: 200;
} */

.flag {
  width: 25px;
  height: 20px;
  background-image: url("./high-res.png");
  background-repeat: no-repeat;
}
.flag.ad {
  background-position: -48px -24px;
}
.flag.ae {
  background-position: -72px -24px;
}
.flag.af {
  background-position: -96px -24px;
}
.flag.ag {
  background-position: -120px -24px;
}
.flag.ai {
  background-position: -144px -24px;
}
.flag.al {
  background-position: -168px -24px;
}
.flag.am {
  background-position: -192px -24px;
}
.flag.an {
  background-position: -216px -24px;
}
.flag.ao {
  background-position: -240px -24px;
}
.flag.aq {
  background-position: -264px -24px;
}
.flag.ar {
  background-position: -288px -24px;
}
.flag.as {
  background-position: -312px -24px;
}
.flag.at {
  background-position: -336px -24px;
}
.flag.au {
  background-position: -360px -24px;
}
.flag.aw {
  background-position: -384px -24px;
}
.flag.ax {
  background-position: 0px -48px;
}
.flag.az {
  background-position: -24px -48px;
}
.flag.ba {
  background-position: -48px -48px;
}
.flag.bb {
  background-position: -72px -48px;
}
.flag.bd {
  background-position: -96px -48px;
}
.flag.be {
  background-position: -120px -48px;
}
.flag.bf {
  background-position: -144px -48px;
}
.flag.bg {
  background-position: -168px -48px;
}
.flag.bh {
  background-position: -192px -48px;
}
.flag.bi {
  background-position: -216px -48px;
}
.flag.bj {
  background-position: -240px -48px;
}
.flag.bl {
  background-position: -264px -48px;
}
.flag.bm {
  background-position: -288px -48px;
}
.flag.bn {
  background-position: -312px -48px;
}
.flag.bo {
  background-position: -336px -48px;
}
.flag.br {
  background-position: -360px -48px;
}
.flag.bs {
  background-position: -384px -48px;
}
.flag.bt {
  background-position: 0px -72px;
}
.flag.bw {
  background-position: -24px -72px;
}
.flag.by {
  background-position: -48px -72px;
}
.flag.bz {
  background-position: -72px -72px;
}
.flag.ca {
  background-position: -96px -72px;
}
.flag.cc {
  background-position: -120px -72px;
}
.flag.cd {
  background-position: -144px -72px;
}
.flag.cf {
  background-position: -168px -72px;
}
.flag.cg {
  background-position: -192px -72px;
}
.flag.ch {
  background-position: -216px -72px;
}
.flag.ci {
  background-position: -240px -72px;
}
.flag.ck {
  background-position: -264px -72px;
}
.flag.cl {
  background-position: -288px -72px;
}
.flag.cm {
  background-position: -312px -72px;
}
.flag.cn {
  background-position: -336px -72px;
}
.flag.co {
  background-position: -360px -72px;
}
.flag.cr {
  background-position: -384px -72px;
}
.flag.cu {
  background-position: 0px -96px;
}
.flag.cv {
  background-position: -24px -96px;
}
.flag.cw {
  background-position: -48px -96px;
}
.flag.cx {
  background-position: -72px -96px;
}
.flag.cy {
  background-position: -96px -96px;
}
.flag.cz {
  background-position: -120px -96px;
}
.flag.de {
  background-position: -144px -96px;
}
.flag.dj {
  background-position: -168px -96px;
}
.flag.dk {
  background-position: -192px -96px;
}
.flag.dm {
  background-position: -216px -96px;
}
.flag.do {
  background-position: -240px -96px;
}
.flag.dz {
  background-position: -264px -96px;
}
.flag.ec {
  background-position: -288px -96px;
}
.flag.ee {
  background-position: -312px -96px;
}
.flag.eg {
  background-position: -336px -96px;
}
.flag.eh {
  background-position: -360px -96px;
}
.flag.er {
  background-position: -384px -96px;
}
.flag.es {
  background-position: 0px -120px;
}
.flag.et {
  background-position: -24px -120px;
}
.flag.eu {
  background-position: -48px -120px;
}
.flag.fi {
  background-position: -72px -120px;
}
.flag.fj {
  background-position: -96px -120px;
}
.flag.fk {
  background-position: -120px -120px;
}
.flag.fm {
  background-position: -144px -120px;
}
.flag.fo {
  background-position: -168px -120px;
}
.flag.fr {
  background-position: -192px -120px;
}
.flag.ga {
  background-position: -216px -120px;
}
.flag.gb {
  background-position: -240px -120px;
}
.flag.gd {
  background-position: -264px -120px;
}
.flag.ge {
  background-position: -288px -120px;
}
.flag.gg {
  background-position: -312px -120px;
}
.flag.gh {
  background-position: -336px -120px;
}
.flag.gi {
  background-position: -360px -120px;
}
.flag.gl {
  background-position: -384px -120px;
}
.flag.gm {
  background-position: 0px -144px;
}
.flag.gn {
  background-position: -24px -144px;
}
.flag.gq {
  background-position: -48px -144px;
}
.flag.gr {
  background-position: -72px -144px;
}
.flag.gs {
  background-position: -96px -144px;
}
.flag.gt {
  background-position: -120px -144px;
}
.flag.gu {
  background-position: -144px -144px;
}
.flag.gw {
  background-position: -168px -144px;
}
.flag.gy {
  background-position: -192px -144px;
}
.flag.hk {
  background-position: -216px -144px;
}
.flag.hn {
  background-position: -240px -144px;
}
.flag.hr {
  background-position: -264px -144px;
}
.flag.ht {
  background-position: -288px -144px;
}
.flag.hu {
  background-position: -312px -144px;
}
.flag.ic {
  background-position: -336px -144px;
}
.flag.id {
  background-position: -360px -144px;
}
.flag.ie {
  background-position: -384px -144px;
}
.flag.il {
  background-position: 0px -168px;
}
.flag.im {
  background-position: -24px -168px;
}
.flag.in {
  background-position: -48px -168px;
}
.flag.iq {
  background-position: -72px -168px;
}
.flag.ir {
  background-position: -96px -168px;
}
.flag.is {
  background-position: -120px -168px;
}
.flag.it {
  background-position: -144px -168px;
}
.flag.je {
  background-position: -168px -168px;
}
.flag.jm {
  background-position: -192px -168px;
}
.flag.jo {
  background-position: -216px -168px;
}
.flag.jp {
  background-position: -240px -168px;
}
.flag.ke {
  background-position: -264px -168px;
}
.flag.kg {
  background-position: -288px -168px;
}
.flag.kh {
  background-position: -312px -168px;
}
.flag.ki {
  background-position: -336px -168px;
}
.flag.km {
  background-position: -360px -168px;
}
.flag.kn {
  background-position: -384px -168px;
}
.flag.kp {
  background-position: 0px -192px;
}
.flag.kr {
  background-position: -24px -192px;
}
.flag.kw {
  background-position: -48px -192px;
}
.flag.ky {
  background-position: -72px -192px;
}
.flag.kz {
  background-position: -96px -192px;
}
.flag.la {
  background-position: -120px -192px;
}
.flag.lb {
  background-position: -144px -192px;
}
.flag.lc {
  background-position: -168px -192px;
}
.flag.li {
  background-position: -192px -192px;
}
.flag.lk {
  background-position: -216px -192px;
}
.flag.lr {
  background-position: -240px -192px;
}
.flag.ls {
  background-position: -264px -192px;
}
.flag.lt {
  background-position: -288px -192px;
}
.flag.lu {
  background-position: -312px -192px;
}
.flag.lv {
  background-position: -336px -192px;
}
.flag.ly {
  background-position: -360px -192px;
}
.flag.ma {
  background-position: -384px -192px;
}
.flag.mc {
  background-position: 0px -216px;
}
.flag.md {
  background-position: -24px -216px;
}
.flag.me {
  background-position: -48px -216px;
}
.flag.mf {
  background-position: -72px -216px;
}
.flag.mg {
  background-position: -96px -216px;
}
.flag.mh {
  background-position: -120px -216px;
}
.flag.mk {
  background-position: -144px -216px;
}
.flag.ml {
  background-position: -168px -216px;
}
.flag.mm {
  background-position: -192px -216px;
}
.flag.mn {
  background-position: -216px -216px;
}
.flag.mo {
  background-position: -240px -216px;
}
.flag.mp {
  background-position: -264px -216px;
}
.flag.mq {
  background-position: -288px -216px;
}
.flag.mr {
  background-position: -312px -216px;
}
.flag.ms {
  background-position: -336px -216px;
}
.flag.mt {
  background-position: -360px -216px;
}
.flag.mu {
  background-position: -384px -216px;
}
.flag.mv {
  background-position: 0px -240px;
}
.flag.mw {
  background-position: -24px -240px;
}
.flag.mx {
  background-position: -48px -240px;
}
.flag.my {
  background-position: -72px -240px;
}
.flag.mz {
  background-position: -96px -240px;
}
.flag.na {
  background-position: -120px -240px;
}
.flag.nc {
  background-position: -144px -240px;
}
.flag.ne {
  background-position: -168px -240px;
}
.flag.nf {
  background-position: -192px -240px;
}
.flag.ng {
  background-position: -216px -240px;
}
.flag.ni {
  background-position: -240px -240px;
}
.flag.nl {
  background-position: -264px -240px;
}
.flag.no {
  background-position: -288px -240px;
}
.flag.np {
  background-position: -312px -240px;
}
.flag.nr {
  background-position: -336px -240px;
}
.flag.nu {
  background-position: -360px -240px;
}
.flag.nz {
  background-position: -384px -240px;
}
.flag.om {
  background-position: 0px -264px;
}
.flag.pa {
  background-position: -24px -264px;
}
.flag.pe {
  background-position: -48px -264px;
}
.flag.pf {
  background-position: -72px -264px;
}
.flag.pg {
  background-position: -96px -264px;
}
.flag.ph {
  background-position: -120px -264px;
}
.flag.pk {
  background-position: -192px -264px;
}
.flag.pl {
  background-position: -216px -264px;
}
.flag.pn {
  background-position: -240px -264px;
}
.flag.pr {
  background-position: -264px -264px;
}
.flag.ps {
  background-position: -288px -264px;
}
.flag.pt {
  background-position: -312px -264px;
}
.flag.pw {
  background-position: -336px -264px;
}
.flag.py {
  background-position: -360px -264px;
}
.flag.qa {
  background-position: -384px -264px;
}
.flag.ro {
  background-position: 0px -288px;
}
.flag.rs {
  background-position: -24px -288px;
}
.flag.ru {
  background-position: -48px -288px;
}
.flag.rw {
  background-position: -72px -288px;
}
.flag.sa {
  background-position: -96px -288px;
}
.flag.sb {
  background-position: -120px -288px;
}
.flag.sc {
  background-position: -144px -288px;
}
.flag.sd {
  background-position: -168px -288px;
}
.flag.se {
  background-position: -192px -288px;
}
.flag.sg {
  background-position: -216px -288px;
}
.flag.sh {
  background-position: -240px -288px;
}
.flag.si {
  background-position: -264px -288px;
}
.flag.sk {
  background-position: -288px -288px;
}
.flag.sl {
  background-position: -312px -288px;
}
.flag.sm {
  background-position: -336px -288px;
}
.flag.sn {
  background-position: -360px -288px;
}
.flag.so {
  background-position: -384px -288px;
}
.flag.sr {
  background-position: 0px -312px;
}
.flag.ss {
  background-position: -24px -312px;
}
.flag.st {
  background-position: -48px -312px;
}
.flag.sv {
  background-position: -72px -312px;
}
.flag.sy {
  background-position: -96px -312px;
}
.flag.sz {
  background-position: -120px -312px;
}
.flag.tc {
  background-position: -144px -312px;
}
.flag.td {
  background-position: -168px -312px;
}
.flag.tf {
  background-position: -192px -312px;
}
.flag.tg {
  background-position: -216px -312px;
}
.flag.th {
  background-position: -240px -312px;
}
.flag.tj {
  background-position: -264px -312px;
}
.flag.tk {
  background-position: -288px -312px;
}
.flag.tl {
  background-position: -312px -312px;
}
.flag.tm {
  background-position: -336px -312px;
}
.flag.tn {
  background-position: -360px -312px;
}
.flag.to {
  background-position: -384px -312px;
}
.flag.tr {
  background-position: 0px -336px;
}
.flag.tt {
  background-position: -24px -336px;
}
.flag.tv {
  background-position: -48px -336px;
}
.flag.tw {
  background-position: -72px -336px;
}
.flag.tz {
  background-position: -96px -336px;
}
.flag.ua {
  background-position: -120px -336px;
}
.flag.ug {
  background-position: -144px -336px;
}
.flag.us {
  background-position: -168px -336px;
}
.flag.uy {
  background-position: -192px -336px;
}
.flag.uz {
  background-position: -216px -336px;
}
.flag.va {
  background-position: -240px -336px;
}
.flag.vc {
  background-position: -264px -336px;
}
.flag.ve {
  background-position: -288px -336px;
}
.flag.vg {
  background-position: -312px -336px;
}
.flag.vi {
  background-position: -336px -336px;
}
.flag.vn {
  background-position: -360px -336px;
}
.flag.vu {
  background-position: -384px -336px;
}
.flag.wf {
  background-position: 0px -360px;
}
.flag.ws {
  background-position: -24px -360px;
}
.flag.ye {
  background-position: -48px -360px;
}
.flag.za {
  background-position: -96px -360px;
}
.flag.zm {
  background-position: -120px -360px;
}
.flag.zw {
  background-position: -144px -360px;
}
.flag* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.flag.hide {
  display: none;
}
.flag.v-hide {
  visibility: hidden;
}
