/* REVIEWED MAR 2021
/* outline: 1px solid red; */ /* DETECT OVERSCROLL */

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  cursor: default;
  /* 
    WEBKIT BROWSERS DONT SUPPORT THE SCROLLBAR ELEMENTS YET
    THATS WHY WE USE THE WEBKIT VERSIONS BELOW
  */
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.3) rgba(255, 255, 255, 0.2); /*THUMB - TRACK*/
}

*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
*::-webkit-scrollbar-thumb {
  background-color: #3caccc; /*rgba(0, 0, 0, 0.3);*/
}
*::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.2);
}

.App {
  text-align: center;
  height: 100%;
  overflow: hidden;
}

#draggable {
  position: absolute;
  pointer-events: none;
  width: 100%;
}
.noScrollBars {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.noScrollBars::-webkit-scrollbar {
  display: none;
}

.headerBarHeight {
  height: 66px;
  max-height: 66px;
  /* min-height: 66px; */
}

.sticky {
  position: sticky;
  position: -webkit-sticky;
}
.zIndex-spinner {
  z-index: 10000;
}
.zIndex-publishAlert {
  z-index: 1500;
}

.buttonHover:hover {
  /* filter: saturate(0.5); */
  filter: brightness(1.2);
  /* filter: hue-rotate(45deg); */
}

.buttonHover:active {
  box-shadow: 1px 1px 5px 5px rgba(0, 0, 0, 0.12);
}

.selectHover:hover {
  border-bottom: 2px solid rgba(0, 0, 0, 0.87) !important;
  margin-bottom: -1px;
}
.selectHoverS:hover {
  border-bottom: 2px solid rgba(60, 172, 204, 0.87) !important;
  margin-bottom: -2px;
}

/* CHANGING THE WIDTH IN THIS CLASS WILL AFFECT IMAGE SIZES PROPERTY */
.smartWidthXs {
  margin-left: auto;
  margin-right: auto;
  width: 230px;
  max-width: min(100%, 230px);
}
/* CHANGING THE WIDTH IN THIS CLASS WILL AFFECT IMAGE SIZES PROPERTY */
.smartWidthSm {
  margin-left: auto;
  margin-right: auto;
  width: 322px;
  max-width: min(100%, 322px);
}
/* CHANGING THE WIDTH IN THIS CLASS WILL AFFECT IMAGE SIZES PROPERTY */
.smartWidthMd {
  margin-left: auto;
  margin-right: auto;
  width: 525px;
  max-width: min(100%, 525px);
}
/* CHANGING THE WIDTH IN THIS CLASS WILL AFFECT IMAGE SIZES PROPERTY */
.smartWidthLg {
  margin-left: auto;
  margin-right: auto;
  width: 675px;
  max-width: min(100%, 675px);
}
/* CHANGING THE WIDTH IN THIS CLASS WILL AFFECT IMAGE SIZES PROPERTY */
.smartWidthXl {
  margin-left: auto;
  margin-right: auto;
  width: 870px;
  max-width: min(100%, 870px);
}
/* CHANGING THE WIDTH IN THIS CLASS WILL AFFECT IMAGE SIZES PROPERTY */
.smartWidthXxl {
  margin-left: auto;
  margin-right: auto;
  width: 1200px;
  max-width: min(100%, 1200px);
}

.boxShadow0 {
  box-shadow: none;
}

.boxShadow1 {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
}
.boxShadow2 {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}
.boxShadow3 {
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14),
    0 3px 3px -2px rgba(0, 0, 0, 0.12), 0 1px 8px 0 rgba(0, 0, 0, 0.2);
}
.boxShadow4 {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(0, 0, 0, 0.2);
}
.boxShadow5 {
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12),
    0 3px 5px -1px rgba(0, 0, 0, 0.2);
}
.boxShadow6 {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
}
.boxShadow7 {
  box-shadow: 0 9px 12px 1px rgba(0, 0, 0, 0.14),
    0 3px 16px 2px rgba(0, 0, 0, 0.12), 0 5px 6px -3px rgba(0, 0, 0, 0.2);
}
.boxShadow8 {
  box-shadow: 0 12px 17px 2px rgba(0, 0, 0, 0.14),
    0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 7px 8px -4px rgba(0, 0, 0, 0.2);
}
.boxShadow9 {
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
    0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
.boxShadow10 {
  box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.2);
}

/**
  * There's no defined standard for the page widths 
  * to target, but the following example responsive 
  * values are often used:
  * 320px.
  * 480px.
  * 600px.
  * 768px.
  * 900px.
  * 1024px.
  * 1200px.
  * 
  * MATERIAL UI LIBRARY USES THESE BREAKPOINTS
  * xs - extra-small 0px
  * sm - small       600px
  * md - medium      960px
  * lg - large       1280px
  * xl - extra-large 1920px
  * 
*/
/*

*/

.mobileOnlyFlex {
  display: flex;
}
.desktopOnlyBlock {
  display: none;
}

@media (min-width: 768px) {
  .mobileOnlyFlex {
    display: none;
  }
  .desktopOnlyBlock {
    display: block;
  }
}

.anim_spinnerMove {
  animation: anim_spinnerMove 5s infinite ease-in-out;
}
@keyframes anim_spinnerMove {
  0% {
    transform: rotateY(0);
  }

  /* Finish changes by here */
  20% {
    transform: rotateY(360deg);
  }

  /* Between 20% and 100%, nothing changes */
  100% {
    transform: rotateY(360deg);
  }
}

.anim_fadeIn_0100 {
  animation: fadeIn 0.1s ease 0s 1 normal forwards;
}
.anim_fadeIn_0110 {
  animation: fadeIn 0.1s ease 1s 1 normal forwards;
}
.anim_fadeIn_0201 {
  animation: fadeIn 0.2s ease 0.1s 1 normal forwards;
}
.anim_fadeIn_0300 {
  animation: fadeIn 0.3s ease 0s 1 normal forwards;
}
.anim_fadeIn_0301 {
  animation: fadeIn 0.3s ease 0.1s 1 normal forwards;
}
.anim_fadeIn_0301 {
  animation: fadeIn 0.3s ease 0.1s 1 normal forwards;
}
.anim_fadeIn_0303 {
  animation: fadeIn 0.3s ease 0.3s 1 normal forwards;
}
.anim_fadeIn_0305 {
  animation: fadeIn 0.3s ease 0.5s 1 normal forwards;
}
.anim_fadeIn_0307 {
  animation: fadeIn 0.3s ease 0.7s 1 normal forwards;
}
.anim_fadeIn_0310 {
  animation: fadeIn 0.3s ease 1s 1 normal forwards;
}
.anim_fadeIn_0500 {
  animation: fadeIn 0.5s ease 0s 1 normal forwards;
}
.anim_fadeIn_0501 {
  animation: fadeIn 0.5s ease 0.1s 1 normal forwards;
}
.anim_fadeIn_0503 {
  animation: fadeIn 0.5s ease 0.3s 1 normal forwards;
}
.anim_fadeIn_0505 {
  animation: fadeIn 0.5s ease 0.5s 1 normal forwards;
}
.anim_fadeIn_0507 {
  animation: fadeIn 0.5s ease 0.7s 1 normal forwards;
}
.anim_fadeIn_0510 {
  animation: fadeIn 0.5s ease 1s 1 normal forwards;
}
.anim_fadeIn_1000 {
  animation: fadeIn 1s ease 0s 1 normal forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.anim_fadeOut_0100 {
  animation: fadeOut 0.1s ease 0s 1 normal forwards;
}
.anim_fadeOut_0103 {
  animation: fadeOut 0.1s ease 0.3s 1 normal forwards;
}
.anim_fadeOut_0300 {
  animation: fadeOut 0.3s ease 0s 1 normal forwards;
}
.anim_fadeOut_0500 {
  animation: fadeOut 0.5s ease 0s 1 normal forwards;
}
.anim_fadeOut_1000 {
  animation: fadeOut 1s ease 0s 1 normal forwards;
}
.anim_fadeOut_0305 {
  animation: fadeOut 0.3s ease 0.5s 1 normal forwards;
}
.anim_fadeOut_0310 {
  animation: fadeOut 0.3s ease 1s 1 normal forwards;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.anim_fadeInOut {
  animation: fadeInOut01 2s ease 0s infinite normal forwards;
}
@keyframes fadeInOut01 {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.anim_fadeInOut003 {
  animation: fadeInOut003 3s ease 0s infinite normal forwards;
}
@keyframes fadeInOut003 {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.anim_moveRight_small_0100 {
  animation: moveRight_small 0.1s ease 0s 1 normal forwards;
}

@keyframes moveRight_small {
  0% {
    transform: translate(-0.75em);
  }
  100% {
    transform: translate(0em);
  }
}

.anim_moveRight_medium_0100 {
  animation: moveRight_medium 0.1s ease 0s 1 normal forwards;
}
.anim_moveRight_medium_0110 {
  animation: moveRight_medium 0.5s ease 2s 1 normal forwards;
}

@keyframes moveRight_medium {
  0% {
    opacity: 0;
    transform: translate(-3.75em);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: translate(0em);
  }
}

.anim_moveRight_large_0200 {
  animation: moveRight_large 0.2s ease 0s 1 normal forwards;
}

@keyframes moveRight_large {
  0% {
    transform: translate(-20.75em);
  }
  100% {
    transform: translate(0em);
  }
}
.anim_moveLeft_small_0100 {
  animation: moveLeft_small 0.1s linear 0s 1 normal forwards;
}
@keyframes moveLeft_small {
  0% {
    transform: translate(0.75em);
  }
  100% {
    transform: translate(0em);
  }
}

.anim_moveLeft_medium_0100 {
  animation: moveLeft_medium 0.1s ease 0s 1 normal forwards;
}

@keyframes moveLeft_medium {
  0% {
    transform: translate(0em);
  }
  100% {
    transform: translate(-3.75em);
  }
}

.anim_moveUp_small_0300 {
  animation: moveUp_small 0.3s ease 0s 1 normal forwards;
}
.anim_moveUp_small_0301 {
  animation: moveUp_small 0.3s ease 0.1s 1 normal forwards;
}
.anim_moveUp_small_0302 {
  animation: moveUp_small 0.3s ease 0.1s 1 normal forwards;
}
.anim_moveUp_small_0303 {
  animation: moveUp_small 0.3s ease 0.3s 1 normal forwards;
}
.anim_moveUp_small_0305 {
  animation: moveUp_small 0.3s ease 0.5s 1 normal forwards;
}
.anim_moveUp_small_0310 {
  animation: moveUp_small 0.3s ease 1s 1 normal forwards;
}

@keyframes moveUp_small {
  0% {
    opacity: 0;
    transform: translate(0, 0.75em);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0em);
  }
}

/*
FONT SIZES

SEARCH FOR FONT-SIZE MEDIUM / FONT-SIZE SMALL TO SEE THE MEDIA QUERIES
*/
.gb-font-1 {
  font-size: 2.25pt !important;
}
.gb-font-2 {
  font-size: 2.25pt !important;
}
.gb-font-3 {
  font-size: 3pt !important;
}
.gb-font-4 {
  font-size: 4pt !important;
}
.gb-font-5 {
  font-size: 5pt !important;
}
.gb-font-6 {
  font-size: 6pt !important;
}
.gb-font-7 {
  font-size: 7pt !important;
}
.gb-font-8 {
  font-size: 8pt !important;
}
.gb-font-9 {
  font-size: 9pt !important;
}
.gb-font-10 {
  font-size: 10pt !important;
}
.gb-font-11 {
  font-size: 11pt !important;
}
.gb-font-12 {
  font-size: 12pt !important;
}
.gb-font-13 {
  font-size: 13pt !important;
}
.gb-font-14 {
  font-size: 14pt !important;
}
.gb-font-15 {
  font-size: 15pt !important;
}
.gb-font-16 {
  font-size: 16pt !important;
}
.gb-font-17 {
  font-size: 17pt !important;
}
.gb-font-18 {
  font-size: 18pt !important;
}
.gb-font-19 {
  font-size: 19pt !important;
}
.gb-font-20 {
  font-size: 20pt !important;
}
.gb-font-21 {
  font-size: 21pt !important;
}
.gb-font-22 {
  font-size: 22pt !important;
}
.gb-font-23 {
  font-size: 23pt !important;
}
.gb-font-24 {
  font-size: 24pt !important;
}
.gb-font-25 {
  font-size: 25pt !important;
}
.gb-font-26 {
  font-size: 26pt !important;
}
.gb-font-27 {
  font-size: 27pt !important;
}
.gb-font-28 {
  font-size: 28pt !important;
}
.gb-font-29 {
  font-size: 29pt !important;
}
.gb-font-30 {
  font-size: 30pt !important;
}
.gb-font-31 {
  font-size: 31pt !important;
}
.gb-font-32 {
  font-size: 32pt !important;
}
.gb-font-33 {
  font-size: 33pt !important;
}
.gb-font-34 {
  font-size: 34pt !important;
}
.gb-font-35 {
  font-size: 35pt !important;
}
.gb-font-36 {
  font-size: 36pt !important;
}
.gb-font-37 {
  font-size: 37pt !important;
}
.gb-font-38 {
  font-size: 38pt !important;
}
.gb-font-39 {
  font-size: 39pt !important;
}
.gb-font-40 {
  font-size: 40pt !important;
}
.gb-font-41 {
  font-size: 41pt !important;
}
.gb-font-42 {
  font-size: 42pt !important;
}
.gb-font-43 {
  font-size: 43pt !important;
}
.gb-font-44 {
  font-size: 44pt !important;
}
.gb-font-45 {
  font-size: 45pt !important;
}
.gb-font-46 {
  font-size: 46pt !important;
}
.gb-font-47 {
  font-size: 47pt !important;
}
.gb-font-48 {
  font-size: 48pt !important;
}
.gb-font-49 {
  font-size: 49pt !important;
}
.gb-font-50 {
  font-size: 50pt !important;
}
.gb-font-51 {
  font-size: 51pt !important;
}
.gb-font-52 {
  font-size: 52pt !important;
}
.gb-font-53 {
  font-size: 53pt !important;
}
.gb-font-54 {
  font-size: 54pt !important;
}
.gb-font-55 {
  font-size: 55pt !important;
}
.gb-font-56 {
  font-size: 56pt !important;
}
.gb-font-57 {
  font-size: 57pt !important;
}
.gb-font-58 {
  font-size: 58pt !important;
}
.gb-font-59 {
  font-size: 59pt !important;
}
.gb-font-60 {
  font-size: 60pt !important;
}
.gb-font-61 {
  font-size: 61pt !important;
}
.gb-font-62 {
  font-size: 62pt !important;
}
.gb-font-63 {
  font-size: 63pt !important;
}
.gb-font-64 {
  font-size: 64pt !important;
}
.gb-font-65 {
  font-size: 65pt !important;
}
.gb-font-66 {
  font-size: 66pt !important;
}
.gb-font-67 {
  font-size: 67pt !important;
}
.gb-font-68 {
  font-size: 68pt !important;
}
.gb-font-69 {
  font-size: 69pt !important;
}
.gb-font-70 {
  font-size: 70pt !important;
}
.gb-font-71 {
  font-size: 71pt !important;
}
.gb-font-72 {
  font-size: 72pt !important;
}
.gb-font-73 {
  font-size: 73pt !important;
}
.gb-font-74 {
  font-size: 74pt !important;
}
.gb-font-75 {
  font-size: 75pt !important;
}
.gb-font-76 {
  font-size: 76pt !important;
}
.gb-font-77 {
  font-size: 77pt !important;
}
.gb-font-78 {
  font-size: 78pt !important;
}
.gb-font-79 {
  font-size: 79pt !important;
}
.gb-font-80 {
  font-size: 80pt !important;
}
.gb-font-81 {
  font-size: 81pt !important;
}
.gb-font-82 {
  font-size: 82pt !important;
}
.gb-font-83 {
  font-size: 83pt !important;
}
.gb-font-84 {
  font-size: 84pt !important;
}
.gb-font-85 {
  font-size: 85pt !important;
}
.gb-font-86 {
  font-size: 86pt !important;
}
.gb-font-87 {
  font-size: 87pt !important;
}
.gb-font-88 {
  font-size: 88pt !important;
}
.gb-font-89 {
  font-size: 89pt !important;
}
.gb-font-90 {
  font-size: 90pt !important;
}
.gb-font-91 {
  font-size: 91pt !important;
}
.gb-font-92 {
  font-size: 92pt !important;
}
.gb-font-93 {
  font-size: 93pt !important;
}
.gb-font-94 {
  font-size: 94pt !important;
}
.gb-font-95 {
  font-size: 95pt !important;
}
.gb-font-96 {
  font-size: 96pt !important;
}
.gb-font-97 {
  font-size: 97pt !important;
}
.gb-font-98 {
  font-size: 98pt !important;
}
.gb-font-99 {
  font-size: 99pt !important;
}
.gb-font-100 {
  font-size: 100pt !important;
}
.gb-font-101 {
  font-size: 101pt !important;
}
.gb-font-102 {
  font-size: 102pt !important;
}
.gb-font-103 {
  font-size: 103pt !important;
}
.gb-font-104 {
  font-size: 104pt !important;
}
.gb-font-105 {
  font-size: 105pt !important;
}
.gb-font-106 {
  font-size: 106pt !important;
}
.gb-font-107 {
  font-size: 107pt !important;
}
.gb-font-108 {
  font-size: 108pt !important;
}
.gb-font-109 {
  font-size: 109pt !important;
}
.gb-font-110 {
  font-size: 110pt !important;
}
.gb-font-111 {
  font-size: 111pt !important;
}
.gb-font-112 {
  font-size: 112pt !important;
}
.gb-font-113 {
  font-size: 113pt !important;
}
.gb-font-114 {
  font-size: 114pt !important;
}
.gb-font-115 {
  font-size: 115pt !important;
}
.gb-font-116 {
  font-size: 116pt !important;
}
.gb-font-117 {
  font-size: 117pt !important;
}
.gb-font-118 {
  font-size: 118pt !important;
}
.gb-font-119 {
  font-size: 119pt !important;
}
.gb-font-120 {
  font-size: 120pt !important;
}
.gb-font-121 {
  font-size: 121pt !important;
}
.gb-font-122 {
  font-size: 122pt !important;
}
.gb-font-123 {
  font-size: 123pt !important;
}
.gb-font-124 {
  font-size: 124pt !important;
}
.gb-font-125 {
  font-size: 125pt !important;
}
.gb-font-126 {
  font-size: 126pt !important;
}
.gb-font-127 {
  font-size: 127pt !important;
}
.gb-font-128 {
  font-size: 128pt !important;
}
.gb-font-129 {
  font-size: 129pt !important;
}
.gb-font-130 {
  font-size: 130pt !important;
}
.gb-font-131 {
  font-size: 131pt !important;
}
.gb-font-132 {
  font-size: 132pt !important;
}
.gb-font-133 {
  font-size: 133pt !important;
}
.gb-font-134 {
  font-size: 134pt !important;
}
.gb-font-135 {
  font-size: 135pt !important;
}
.gb-font-136 {
  font-size: 136pt !important;
}
.gb-font-137 {
  font-size: 137pt !important;
}
.gb-font-138 {
  font-size: 138pt !important;
}
.gb-font-139 {
  font-size: 139pt !important;
}
.gb-font-140 {
  font-size: 140pt !important;
}
.gb-font-141 {
  font-size: 141pt !important;
}
.gb-font-142 {
  font-size: 142pt !important;
}
.gb-font-143 {
  font-size: 143pt !important;
}
.gb-font-144 {
  font-size: 144pt !important;
}
.gb-font-145 {
  font-size: 145pt !important;
}
.gb-font-146 {
  font-size: 146pt !important;
}
.gb-font-147 {
  font-size: 147pt !important;
}
.gb-font-148 {
  font-size: 148pt !important;
}
.gb-font-149 {
  font-size: 149pt !important;
}
.gb-font-150 {
  font-size: 150pt !important;
}
.gb-font-151 {
  font-size: 151pt !important;
}
.gb-font-152 {
  font-size: 152pt !important;
}
.gb-font-153 {
  font-size: 153pt !important;
}
.gb-font-154 {
  font-size: 154pt !important;
}
.gb-font-155 {
  font-size: 155pt !important;
}
.gb-font-156 {
  font-size: 156pt !important;
}
.gb-font-157 {
  font-size: 157pt !important;
}
.gb-font-158 {
  font-size: 158pt !important;
}
.gb-font-159 {
  font-size: 159pt !important;
}
.gb-font-160 {
  font-size: 160pt !important;
}
.gb-font-161 {
  font-size: 161pt !important;
}
.gb-font-162 {
  font-size: 162pt !important;
}
.gb-font-163 {
  font-size: 163pt !important;
}
.gb-font-164 {
  font-size: 164pt !important;
}
.gb-font-165 {
  font-size: 165pt !important;
}
.gb-font-166 {
  font-size: 166pt !important;
}
.gb-font-167 {
  font-size: 167pt !important;
}
.gb-font-168 {
  font-size: 168pt !important;
}
.gb-font-169 {
  font-size: 169pt !important;
}
.gb-font-170 {
  font-size: 170pt !important;
}
.gb-font-171 {
  font-size: 171pt !important;
}
.gb-font-172 {
  font-size: 172pt !important;
}
.gb-font-173 {
  font-size: 173pt !important;
}
.gb-font-174 {
  font-size: 174pt !important;
}
.gb-font-175 {
  font-size: 175pt !important;
}
.gb-font-176 {
  font-size: 176pt !important;
}
.gb-font-177 {
  font-size: 177pt !important;
}
.gb-font-178 {
  font-size: 178pt !important;
}
.gb-font-179 {
  font-size: 179pt !important;
}
.gb-font-180 {
  font-size: 180pt !important;
}
.gb-font-181 {
  font-size: 181pt !important;
}
.gb-font-182 {
  font-size: 182pt !important;
}
.gb-font-183 {
  font-size: 183pt !important;
}
.gb-font-184 {
  font-size: 184pt !important;
}
.gb-font-185 {
  font-size: 185pt !important;
}
.gb-font-186 {
  font-size: 186pt !important;
}
.gb-font-187 {
  font-size: 187pt !important;
}
.gb-font-188 {
  font-size: 188pt !important;
}
.gb-font-189 {
  font-size: 189pt !important;
}
.gb-font-190 {
  font-size: 190pt !important;
}
.gb-font-191 {
  font-size: 191pt !important;
}
.gb-font-192 {
  font-size: 192pt !important;
}
.gb-font-193 {
  font-size: 193pt !important;
}
.gb-font-194 {
  font-size: 194pt !important;
}
.gb-font-195 {
  font-size: 195pt !important;
}
.gb-font-196 {
  font-size: 196pt !important;
}
.gb-font-197 {
  font-size: 197pt !important;
}
.gb-font-198 {
  font-size: 198pt !important;
}
.gb-font-199 {
  font-size: 199pt !important;
}
.gb-font-200 {
  font-size: 200pt !important;
}
.gb-font-201 {
  font-size: 201pt !important;
}
.gb-font-202 {
  font-size: 202pt !important;
}
.gb-font-203 {
  font-size: 203pt !important;
}
.gb-font-204 {
  font-size: 204pt !important;
}
.gb-font-205 {
  font-size: 205pt !important;
}
.gb-font-206 {
  font-size: 206pt !important;
}
.gb-font-207 {
  font-size: 207pt !important;
}
.gb-font-208 {
  font-size: 208pt !important;
}
.gb-font-209 {
  font-size: 209pt !important;
}
.gb-font-210 {
  font-size: 210pt !important;
}
.gb-font-211 {
  font-size: 211pt !important;
}
.gb-font-212 {
  font-size: 212pt !important;
}
.gb-font-213 {
  font-size: 213pt !important;
}
.gb-font-214 {
  font-size: 214pt !important;
}
.gb-font-215 {
  font-size: 215pt !important;
}
.gb-font-216 {
  font-size: 216pt !important;
}
.gb-font-217 {
  font-size: 217pt !important;
}
.gb-font-218 {
  font-size: 218pt !important;
}
.gb-font-219 {
  font-size: 219pt !important;
}
.gb-font-220 {
  font-size: 220pt !important;
}
.gb-font-221 {
  font-size: 221pt !important;
}
.gb-font-222 {
  font-size: 222pt !important;
}
.gb-font-223 {
  font-size: 223pt !important;
}
.gb-font-224 {
  font-size: 224pt !important;
}
.gb-font-225 {
  font-size: 225pt !important;
}
.gb-font-226 {
  font-size: 226pt !important;
}
.gb-font-227 {
  font-size: 227pt !important;
}
.gb-font-228 {
  font-size: 228pt !important;
}
.gb-font-229 {
  font-size: 229pt !important;
}
.gb-font-230 {
  font-size: 230pt !important;
}
.gb-font-231 {
  font-size: 231pt !important;
}
.gb-font-232 {
  font-size: 232pt !important;
}
.gb-font-233 {
  font-size: 233pt !important;
}
.gb-font-234 {
  font-size: 234pt !important;
}
.gb-font-235 {
  font-size: 235pt !important;
}
.gb-font-236 {
  font-size: 236pt !important;
}
.gb-font-237 {
  font-size: 237pt !important;
}
.gb-font-238 {
  font-size: 238pt !important;
}
.gb-font-239 {
  font-size: 239pt !important;
}
.gb-font-240 {
  font-size: 240pt !important;
}
.gb-font-241 {
  font-size: 241pt !important;
}
.gb-font-242 {
  font-size: 242pt !important;
}
.gb-font-243 {
  font-size: 243pt !important;
}
.gb-font-244 {
  font-size: 244pt !important;
}
.gb-font-245 {
  font-size: 245pt !important;
}
.gb-font-246 {
  font-size: 246pt !important;
}
.gb-font-247 {
  font-size: 247pt !important;
}
.gb-font-248 {
  font-size: 248pt !important;
}
.gb-font-249 {
  font-size: 249pt !important;
}
.gb-font-250 {
  font-size: 250pt !important;
}
.gb-font-251 {
  font-size: 251pt !important;
}
.gb-font-252 {
  font-size: 252pt !important;
}
.gb-font-253 {
  font-size: 253pt !important;
}
.gb-font-254 {
  font-size: 254pt !important;
}
.gb-font-255 {
  font-size: 255pt !important;
}
.gb-font-256 {
  font-size: 256pt !important;
}
.gb-font-257 {
  font-size: 257pt !important;
}
.gb-font-258 {
  font-size: 258pt !important;
}
.gb-font-259 {
  font-size: 259pt !important;
}
.gb-font-260 {
  font-size: 260pt !important;
}
.gb-font-261 {
  font-size: 261pt !important;
}
.gb-font-262 {
  font-size: 262pt !important;
}
.gb-font-263 {
  font-size: 263pt !important;
}
.gb-font-264 {
  font-size: 264pt !important;
}
.gb-font-265 {
  font-size: 265pt !important;
}
.gb-font-266 {
  font-size: 266pt !important;
}
.gb-font-267 {
  font-size: 267pt !important;
}
.gb-font-268 {
  font-size: 268pt !important;
}
.gb-font-269 {
  font-size: 269pt !important;
}
.gb-font-270 {
  font-size: 270pt !important;
}
.gb-font-271 {
  font-size: 271pt !important;
}
.gb-font-272 {
  font-size: 272pt !important;
}
.gb-font-273 {
  font-size: 273pt !important;
}
.gb-font-274 {
  font-size: 274pt !important;
}
.gb-font-275 {
  font-size: 275pt !important;
}
.gb-font-276 {
  font-size: 276pt !important;
}
.gb-font-277 {
  font-size: 277pt !important;
}
.gb-font-278 {
  font-size: 278pt !important;
}
.gb-font-279 {
  font-size: 279pt !important;
}
.gb-font-280 {
  font-size: 280pt !important;
}
.gb-font-281 {
  font-size: 281pt !important;
}
.gb-font-282 {
  font-size: 282pt !important;
}
.gb-font-283 {
  font-size: 283pt !important;
}
.gb-font-284 {
  font-size: 284pt !important;
}
.gb-font-285 {
  font-size: 285pt !important;
}
.gb-font-286 {
  font-size: 286pt !important;
}
.gb-font-287 {
  font-size: 287pt !important;
}
.gb-font-288 {
  font-size: 288pt !important;
}
.gb-font-289 {
  font-size: 289pt !important;
}
.gb-font-290 {
  font-size: 290pt !important;
}
.gb-font-291 {
  font-size: 291pt !important;
}
.gb-font-292 {
  font-size: 292pt !important;
}
.gb-font-293 {
  font-size: 293pt !important;
}
.gb-font-294 {
  font-size: 294pt !important;
}
.gb-font-295 {
  font-size: 295pt !important;
}
.gb-font-296 {
  font-size: 296pt !important;
}
.gb-font-297 {
  font-size: 297pt !important;
}
.gb-font-298 {
  font-size: 298pt !important;
}
.gb-font-299 {
  font-size: 299pt !important;
}
.gb-font-300 {
  font-size: 300pt !important;
}
.gb-font-301 {
  font-size: 301pt !important;
}
.gb-font-302 {
  font-size: 302pt !important;
}
.gb-font-303 {
  font-size: 303pt !important;
}
.gb-font-304 {
  font-size: 304pt !important;
}
.gb-font-305 {
  font-size: 305pt !important;
}
.gb-font-306 {
  font-size: 306pt !important;
}
.gb-font-307 {
  font-size: 307pt !important;
}
.gb-font-308 {
  font-size: 308pt !important;
}
.gb-font-309 {
  font-size: 309pt !important;
}
.gb-font-310 {
  font-size: 310pt !important;
}
.gb-font-311 {
  font-size: 311pt !important;
}
.gb-font-312 {
  font-size: 312pt !important;
}
.gb-font-313 {
  font-size: 313pt !important;
}
.gb-font-314 {
  font-size: 314pt !important;
}
.gb-font-315 {
  font-size: 315pt !important;
}
.gb-font-316 {
  font-size: 316pt !important;
}
.gb-font-317 {
  font-size: 317pt !important;
}
.gb-font-318 {
  font-size: 318pt !important;
}
.gb-font-319 {
  font-size: 319pt !important;
}
.gb-font-320 {
  font-size: 320pt !important;
}
.gb-font-321 {
  font-size: 321pt !important;
}
.gb-font-322 {
  font-size: 322pt !important;
}
.gb-font-323 {
  font-size: 323pt !important;
}
.gb-font-324 {
  font-size: 324pt !important;
}
.gb-font-325 {
  font-size: 325pt !important;
}
.gb-font-326 {
  font-size: 326pt !important;
}
.gb-font-327 {
  font-size: 327pt !important;
}
.gb-font-328 {
  font-size: 328pt !important;
}
.gb-font-329 {
  font-size: 329pt !important;
}
.gb-font-330 {
  font-size: 330pt !important;
}
.gb-font-331 {
  font-size: 331pt !important;
}
.gb-font-332 {
  font-size: 332pt !important;
}
.gb-font-333 {
  font-size: 333pt !important;
}
.gb-font-334 {
  font-size: 334pt !important;
}
.gb-font-335 {
  font-size: 335pt !important;
}
.gb-font-336 {
  font-size: 336pt !important;
}
.gb-font-337 {
  font-size: 337pt !important;
}
.gb-font-338 {
  font-size: 338pt !important;
}
.gb-font-339 {
  font-size: 339pt !important;
}
.gb-font-340 {
  font-size: 340pt !important;
}
.gb-font-341 {
  font-size: 341pt !important;
}
.gb-font-342 {
  font-size: 342pt !important;
}
.gb-font-343 {
  font-size: 343pt !important;
}
.gb-font-344 {
  font-size: 344pt !important;
}
.gb-font-345 {
  font-size: 345pt !important;
}
.gb-font-346 {
  font-size: 346pt !important;
}
.gb-font-347 {
  font-size: 347pt !important;
}
.gb-font-348 {
  font-size: 348pt !important;
}
.gb-font-349 {
  font-size: 349pt !important;
}
.gb-font-350 {
  font-size: 350pt !important;
}
.gb-font-351 {
  font-size: 351pt !important;
}
.gb-font-352 {
  font-size: 352pt !important;
}
.gb-font-353 {
  font-size: 353pt !important;
}
.gb-font-354 {
  font-size: 354pt !important;
}
.gb-font-355 {
  font-size: 355pt !important;
}
.gb-font-356 {
  font-size: 356pt !important;
}
.gb-font-357 {
  font-size: 357pt !important;
}
.gb-font-358 {
  font-size: 358pt !important;
}
.gb-font-359 {
  font-size: 359pt !important;
}
.gb-font-360 {
  font-size: 360pt !important;
}
.gb-font-361 {
  font-size: 361pt !important;
}
.gb-font-362 {
  font-size: 362pt !important;
}
.gb-font-363 {
  font-size: 363pt !important;
}
.gb-font-364 {
  font-size: 364pt !important;
}
.gb-font-365 {
  font-size: 365pt !important;
}
.gb-font-366 {
  font-size: 366pt !important;
}
.gb-font-367 {
  font-size: 367pt !important;
}
.gb-font-368 {
  font-size: 368pt !important;
}
.gb-font-369 {
  font-size: 369pt !important;
}
.gb-font-370 {
  font-size: 370pt !important;
}
.gb-font-371 {
  font-size: 371pt !important;
}
.gb-font-372 {
  font-size: 372pt !important;
}
.gb-font-373 {
  font-size: 373pt !important;
}
.gb-font-374 {
  font-size: 374pt !important;
}
.gb-font-375 {
  font-size: 375pt !important;
}
.gb-font-376 {
  font-size: 376pt !important;
}
.gb-font-377 {
  font-size: 377pt !important;
}
.gb-font-378 {
  font-size: 378pt !important;
}
.gb-font-379 {
  font-size: 379pt !important;
}
.gb-font-380 {
  font-size: 380pt !important;
}
.gb-font-381 {
  font-size: 381pt !important;
}
.gb-font-382 {
  font-size: 382pt !important;
}
.gb-font-383 {
  font-size: 383pt !important;
}
.gb-font-384 {
  font-size: 384pt !important;
}
.gb-font-385 {
  font-size: 385pt !important;
}
.gb-font-386 {
  font-size: 386pt !important;
}
.gb-font-387 {
  font-size: 387pt !important;
}
.gb-font-388 {
  font-size: 388pt !important;
}
.gb-font-389 {
  font-size: 389pt !important;
}
.gb-font-390 {
  font-size: 390pt !important;
}
.gb-font-391 {
  font-size: 391pt !important;
}
.gb-font-392 {
  font-size: 392pt !important;
}
.gb-font-393 {
  font-size: 393pt !important;
}
.gb-font-394 {
  font-size: 394pt !important;
}
.gb-font-395 {
  font-size: 395pt !important;
}
.gb-font-396 {
  font-size: 396pt !important;
}
.gb-font-397 {
  font-size: 397pt !important;
}
.gb-font-398 {
  font-size: 398pt !important;
}
.gb-font-399 {
  font-size: 399pt !important;
}
.gb-font-400 {
  font-size: 400pt !important;
}
.gb-font-401 {
  font-size: 401pt !important;
}
.gb-font-402 {
  font-size: 402pt !important;
}
.gb-font-403 {
  font-size: 403pt !important;
}
.gb-font-404 {
  font-size: 404pt !important;
}
.gb-font-405 {
  font-size: 405pt !important;
}
.gb-font-406 {
  font-size: 406pt !important;
}
.gb-font-407 {
  font-size: 407pt !important;
}
.gb-font-408 {
  font-size: 408pt !important;
}
.gb-font-409 {
  font-size: 409pt !important;
}
.gb-font-410 {
  font-size: 410pt !important;
}
.gb-font-411 {
  font-size: 411pt !important;
}
.gb-font-412 {
  font-size: 412pt !important;
}
.gb-font-413 {
  font-size: 413pt !important;
}
.gb-font-414 {
  font-size: 414pt !important;
}
.gb-font-415 {
  font-size: 415pt !important;
}
.gb-font-416 {
  font-size: 416pt !important;
}
.gb-font-417 {
  font-size: 417pt !important;
}
.gb-font-418 {
  font-size: 418pt !important;
}
.gb-font-419 {
  font-size: 419pt !important;
}
.gb-font-420 {
  font-size: 420pt !important;
}
.gb-font-421 {
  font-size: 421pt !important;
}
.gb-font-422 {
  font-size: 422pt !important;
}
.gb-font-423 {
  font-size: 423pt !important;
}
.gb-font-424 {
  font-size: 424pt !important;
}
.gb-font-425 {
  font-size: 425pt !important;
}
.gb-font-426 {
  font-size: 426pt !important;
}
.gb-font-427 {
  font-size: 427pt !important;
}
.gb-font-428 {
  font-size: 428pt !important;
}
.gb-font-429 {
  font-size: 429pt !important;
}
.gb-font-430 {
  font-size: 430pt !important;
}
.gb-font-431 {
  font-size: 431pt !important;
}
.gb-font-432 {
  font-size: 432pt !important;
}
.gb-font-433 {
  font-size: 433pt !important;
}
.gb-font-434 {
  font-size: 434pt !important;
}
.gb-font-435 {
  font-size: 435pt !important;
}
.gb-font-436 {
  font-size: 436pt !important;
}
.gb-font-437 {
  font-size: 437pt !important;
}
.gb-font-438 {
  font-size: 438pt !important;
}
.gb-font-439 {
  font-size: 439pt !important;
}
.gb-font-440 {
  font-size: 440pt !important;
}
.gb-font-441 {
  font-size: 441pt !important;
}
.gb-font-442 {
  font-size: 442pt !important;
}
.gb-font-443 {
  font-size: 443pt !important;
}
.gb-font-444 {
  font-size: 444pt !important;
}
.gb-font-445 {
  font-size: 445pt !important;
}
.gb-font-446 {
  font-size: 446pt !important;
}
.gb-font-447 {
  font-size: 447pt !important;
}
.gb-font-448 {
  font-size: 448pt !important;
}
.gb-font-449 {
  font-size: 449pt !important;
}
.gb-font-450 {
  font-size: 450pt !important;
}
.gb-font-451 {
  font-size: 451pt !important;
}
.gb-font-452 {
  font-size: 452pt !important;
}
.gb-font-453 {
  font-size: 453pt !important;
}
.gb-font-454 {
  font-size: 454pt !important;
}
.gb-font-455 {
  font-size: 455pt !important;
}
.gb-font-456 {
  font-size: 456pt !important;
}
.gb-font-457 {
  font-size: 457pt !important;
}
.gb-font-458 {
  font-size: 458pt !important;
}
.gb-font-459 {
  font-size: 459pt !important;
}
.gb-font-460 {
  font-size: 460pt !important;
}
.gb-font-461 {
  font-size: 461pt !important;
}
.gb-font-462 {
  font-size: 462pt !important;
}
.gb-font-463 {
  font-size: 463pt !important;
}
.gb-font-464 {
  font-size: 464pt !important;
}
.gb-font-465 {
  font-size: 465pt !important;
}
.gb-font-466 {
  font-size: 466pt !important;
}
.gb-font-467 {
  font-size: 467pt !important;
}
.gb-font-468 {
  font-size: 468pt !important;
}
.gb-font-469 {
  font-size: 469pt !important;
}
.gb-font-470 {
  font-size: 470pt !important;
}
.gb-font-471 {
  font-size: 471pt !important;
}
.gb-font-472 {
  font-size: 472pt !important;
}
.gb-font-473 {
  font-size: 473pt !important;
}
.gb-font-474 {
  font-size: 474pt !important;
}
.gb-font-475 {
  font-size: 475pt !important;
}
.gb-font-476 {
  font-size: 476pt !important;
}
.gb-font-477 {
  font-size: 477pt !important;
}
.gb-font-478 {
  font-size: 478pt !important;
}
.gb-font-479 {
  font-size: 479pt !important;
}
.gb-font-480 {
  font-size: 480pt !important;
}
.gb-font-481 {
  font-size: 481pt !important;
}
.gb-font-482 {
  font-size: 482pt !important;
}
.gb-font-483 {
  font-size: 483pt !important;
}
.gb-font-484 {
  font-size: 484pt !important;
}
.gb-font-485 {
  font-size: 485pt !important;
}
.gb-font-486 {
  font-size: 486pt !important;
}
.gb-font-487 {
  font-size: 487pt !important;
}
.gb-font-488 {
  font-size: 488pt !important;
}
.gb-font-489 {
  font-size: 489pt !important;
}
.gb-font-490 {
  font-size: 490pt !important;
}
.gb-font-491 {
  font-size: 491pt !important;
}
.gb-font-492 {
  font-size: 492pt !important;
}
.gb-font-493 {
  font-size: 493pt !important;
}
.gb-font-494 {
  font-size: 494pt !important;
}
.gb-font-495 {
  font-size: 495pt !important;
}
.gb-font-496 {
  font-size: 496pt !important;
}
.gb-font-497 {
  font-size: 497pt !important;
}
.gb-font-498 {
  font-size: 498pt !important;
}
.gb-font-499 {
  font-size: 499pt !important;
}
.gb-font-500 {
  font-size: 500pt !important;
}

/* FONT-SIZE SMALL */
@media only screen and (max-width: 479px) {
  .gb-font-17 {
    font-size: 16pt !important;
  }
  .gb-font-18 {
    font-size: 17pt !important;
  }
  .gb-font-19 {
    font-size: 17pt !important;
  }
  .gb-font-20 {
    font-size: 18pt !important;
  }
  .gb-font-21 {
    font-size: 18pt !important;
  }
  .gb-font-22 {
    font-size: 19pt !important;
  }
  .gb-font-23 {
    font-size: 19pt !important;
  }
  .gb-font-24 {
    font-size: 20pt !important;
  }
  .gb-font-25 {
    font-size: 20pt !important;
  }
  .gb-font-26 {
    font-size: 21pt !important;
  }
  .gb-font-27 {
    font-size: 21pt !important;
  }
  .gb-font-28 {
    font-size: 22pt !important;
  }
  .gb-font-29 {
    font-size: 22pt !important;
  }
  .gb-font-30 {
    font-size: 23pt !important;
  }
  .gb-font-31 {
    font-size: 23pt !important;
  }
  .gb-font-32 {
    font-size: 24pt !important;
  }
  .gb-font-33 {
    font-size: 24pt !important;
  }
  .gb-font-34 {
    font-size: 25pt !important;
  }
  .gb-font-35 {
    font-size: 25pt !important;
  }
  .gb-font-36 {
    font-size: 26pt !important;
  }
  .gb-font-37 {
    font-size: 26pt !important;
  }
  .gb-font-38 {
    font-size: 27pt !important;
  }
  .gb-font-39 {
    font-size: 27pt !important;
  }
  .gb-font-40 {
    font-size: 28pt !important;
  }
  .gb-font-41 {
    font-size: 28pt !important;
  }
  .gb-font-42 {
    font-size: 29pt !important;
  }
  .gb-font-43 {
    font-size: 29pt !important;
  }
  .gb-font-44 {
    font-size: 30pt !important;
  }
  .gb-font-45 {
    font-size: 30pt !important;
  }
  .gb-font-46 {
    font-size: 31pt !important;
  }
  .gb-font-47 {
    font-size: 31pt !important;
  }
  .gb-font-48 {
    font-size: 32pt !important;
  }
  .gb-font-49 {
    font-size: 32pt !important;
  }
  .gb-font-50 {
    font-size: 33pt !important;
  }
  .gb-font-51 {
    font-size: 33pt !important;
  }
  .gb-font-52 {
    font-size: 34pt !important;
  }
  .gb-font-53 {
    font-size: 34pt !important;
  }
  .gb-font-54 {
    font-size: 35pt !important;
  }
  .gb-font-55 {
    font-size: 35pt !important;
  }
  .gb-font-56 {
    font-size: 36pt !important;
  }
  .gb-font-57 {
    font-size: 36pt !important;
  }
  .gb-font-58 {
    font-size: 37pt !important;
  }
  .gb-font-59 {
    font-size: 37pt !important;
  }
  .gb-font-60 {
    font-size: 38pt !important;
  }
  .gb-font-61 {
    font-size: 38pt !important;
  }
  .gb-font-62 {
    font-size: 39pt !important;
  }
  .gb-font-63 {
    font-size: 39pt !important;
  }
  .gb-font-64 {
    font-size: 40pt !important;
  }
  .gb-font-65 {
    font-size: 40pt !important;
  }
  .gb-font-66 {
    font-size: 41pt !important;
  }
  .gb-font-67 {
    font-size: 41pt !important;
  }
  .gb-font-68 {
    font-size: 42pt !important;
  }
  .gb-font-69 {
    font-size: 42pt !important;
  }
  .gb-font-70 {
    font-size: 43pt !important;
  }
  .gb-font-71 {
    font-size: 43pt !important;
  }
  .gb-font-72 {
    font-size: 44pt !important;
  }
  .gb-font-73 {
    font-size: 44pt !important;
  }
  .gb-font-74 {
    font-size: 45pt !important;
  }
  .gb-font-75 {
    font-size: 45pt !important;
  }
  .gb-font-76 {
    font-size: 46pt !important;
  }
  .gb-font-77 {
    font-size: 46pt !important;
  }
  .gb-font-78 {
    font-size: 47pt !important;
  }
  .gb-font-79 {
    font-size: 47pt !important;
  }
  .gb-font-80 {
    font-size: 48pt !important;
  }
  .gb-font-81 {
    font-size: 48pt !important;
  }
  .gb-font-82 {
    font-size: 49pt !important;
  }
  .gb-font-83 {
    font-size: 49pt !important;
  }
  .gb-font-84 {
    font-size: 50pt !important;
  }
  .gb-font-85 {
    font-size: 50pt !important;
  }
  .gb-font-86 {
    font-size: 51pt !important;
  }
  .gb-font-87 {
    font-size: 51pt !important;
  }
  .gb-font-88 {
    font-size: 52pt !important;
  }
  .gb-font-89 {
    font-size: 52pt !important;
  }
  .gb-font-90 {
    font-size: 53pt !important;
  }
  .gb-font-91 {
    font-size: 53pt !important;
  }
  .gb-font-92 {
    font-size: 54pt !important;
  }
  .gb-font-93 {
    font-size: 54pt !important;
  }
  .gb-font-94 {
    font-size: 55pt !important;
  }
  .gb-font-95 {
    font-size: 55pt !important;
  }
  .gb-font-96 {
    font-size: 56pt !important;
  }
  .gb-font-97 {
    font-size: 56pt !important;
  }
  .gb-font-98 {
    font-size: 57pt !important;
  }
  .gb-font-99 {
    font-size: 57pt !important;
  }
  .gb-font-100 {
    font-size: 58pt !important;
  }
  .gb-font-101 {
    font-size: 58pt !important;
  }
  .gb-font-102 {
    font-size: 59pt !important;
  }
  .gb-font-103 {
    font-size: 59pt !important;
  }
  .gb-font-104 {
    font-size: 60pt !important;
  }
  .gb-font-105 {
    font-size: 60pt !important;
  }
  .gb-font-106 {
    font-size: 61pt !important;
  }
  .gb-font-107 {
    font-size: 61pt !important;
  }
  .gb-font-108 {
    font-size: 62pt !important;
  }
  .gb-font-109 {
    font-size: 62pt !important;
  }
  .gb-font-110 {
    font-size: 63pt !important;
  }
  .gb-font-111 {
    font-size: 63pt !important;
  }
  .gb-font-112 {
    font-size: 64pt !important;
  }
  .gb-font-113 {
    font-size: 64pt !important;
  }
  .gb-font-114 {
    font-size: 65pt !important;
  }
  .gb-font-115 {
    font-size: 65pt !important;
  }
  .gb-font-116 {
    font-size: 66pt !important;
  }
  .gb-font-117 {
    font-size: 66pt !important;
  }
  .gb-font-118 {
    font-size: 67pt !important;
  }
  .gb-font-119 {
    font-size: 67pt !important;
  }
  .gb-font-120 {
    font-size: 68pt !important;
  }
  .gb-font-121 {
    font-size: 68pt !important;
  }
  .gb-font-122 {
    font-size: 69pt !important;
  }
  .gb-font-123 {
    font-size: 69pt !important;
  }
  .gb-font-124 {
    font-size: 70pt !important;
  }
  .gb-font-125 {
    font-size: 70pt !important;
  }
  .gb-font-126 {
    font-size: 71pt !important;
  }
  .gb-font-127 {
    font-size: 71pt !important;
  }
  .gb-font-128 {
    font-size: 72pt !important;
  }
  .gb-font-129 {
    font-size: 72pt !important;
  }
  .gb-font-130 {
    font-size: 73pt !important;
  }
  .gb-font-131 {
    font-size: 73pt !important;
  }
  .gb-font-132 {
    font-size: 74pt !important;
  }
  .gb-font-133 {
    font-size: 74pt !important;
  }
  .gb-font-134 {
    font-size: 75pt !important;
  }
  .gb-font-135 {
    font-size: 75pt !important;
  }
  .gb-font-136 {
    font-size: 76pt !important;
  }
  .gb-font-137 {
    font-size: 76pt !important;
  }
  .gb-font-138 {
    font-size: 77pt !important;
  }
  .gb-font-139 {
    font-size: 77pt !important;
  }
  .gb-font-140 {
    font-size: 78pt !important;
  }
  .gb-font-141 {
    font-size: 78pt !important;
  }
  .gb-font-142 {
    font-size: 79pt !important;
  }
  .gb-font-143 {
    font-size: 79pt !important;
  }
  .gb-font-144 {
    font-size: 80pt !important;
  }
  .gb-font-145 {
    font-size: 80pt !important;
  }
  .gb-font-146 {
    font-size: 81pt !important;
  }
  .gb-font-147 {
    font-size: 81pt !important;
  }
  .gb-font-148 {
    font-size: 82pt !important;
  }
  .gb-font-149 {
    font-size: 82pt !important;
  }
  .gb-font-150 {
    font-size: 83pt !important;
  }
  .gb-font-151 {
    font-size: 83pt !important;
  }
  .gb-font-152 {
    font-size: 84pt !important;
  }
  .gb-font-153 {
    font-size: 84pt !important;
  }
  .gb-font-154 {
    font-size: 85pt !important;
  }
  .gb-font-155 {
    font-size: 85pt !important;
  }
  .gb-font-156 {
    font-size: 86pt !important;
  }
  .gb-font-157 {
    font-size: 86pt !important;
  }
  .gb-font-158 {
    font-size: 87pt !important;
  }
  .gb-font-159 {
    font-size: 87pt !important;
  }
  .gb-font-160 {
    font-size: 88pt !important;
  }
  .gb-font-161 {
    font-size: 88pt !important;
  }
  .gb-font-162 {
    font-size: 89pt !important;
  }
  .gb-font-163 {
    font-size: 89pt !important;
  }
  .gb-font-164 {
    font-size: 90pt !important;
  }
  .gb-font-165 {
    font-size: 90pt !important;
  }
  .gb-font-166 {
    font-size: 91pt !important;
  }
  .gb-font-167 {
    font-size: 91pt !important;
  }
  .gb-font-168 {
    font-size: 92pt !important;
  }
  .gb-font-169 {
    font-size: 92pt !important;
  }
  .gb-font-170 {
    font-size: 93pt !important;
  }
  .gb-font-171 {
    font-size: 93pt !important;
  }
  .gb-font-172 {
    font-size: 94pt !important;
  }
  .gb-font-173 {
    font-size: 94pt !important;
  }
  .gb-font-174 {
    font-size: 95pt !important;
  }
  .gb-font-175 {
    font-size: 95pt !important;
  }
  .gb-font-176 {
    font-size: 96pt !important;
  }
  .gb-font-177 {
    font-size: 96pt !important;
  }
  .gb-font-178 {
    font-size: 97pt !important;
  }
  .gb-font-179 {
    font-size: 97pt !important;
  }
  .gb-font-180 {
    font-size: 98pt !important;
  }
  .gb-font-181 {
    font-size: 98pt !important;
  }
  .gb-font-182 {
    font-size: 99pt !important;
  }
  .gb-font-183 {
    font-size: 99pt !important;
  }
  .gb-font-184 {
    font-size: 100pt !important;
  }
  .gb-font-185 {
    font-size: 100pt !important;
  }
  .gb-font-186 {
    font-size: 101pt !important;
  }
  .gb-font-187 {
    font-size: 101pt !important;
  }
  .gb-font-188 {
    font-size: 102pt !important;
  }
  .gb-font-189 {
    font-size: 102pt !important;
  }
  .gb-font-190 {
    font-size: 103pt !important;
  }
  .gb-font-191 {
    font-size: 103pt !important;
  }
  .gb-font-192 {
    font-size: 104pt !important;
  }
  .gb-font-193 {
    font-size: 104pt !important;
  }
  .gb-font-194 {
    font-size: 105pt !important;
  }
  .gb-font-195 {
    font-size: 105pt !important;
  }
  .gb-font-196 {
    font-size: 106pt !important;
  }
  .gb-font-197 {
    font-size: 106pt !important;
  }
  .gb-font-198 {
    font-size: 107pt !important;
  }
  .gb-font-199 {
    font-size: 107pt !important;
  }
  .gb-font-200 {
    font-size: 108pt !important;
  }
  .gb-font-201 {
    font-size: 108pt !important;
  }
  .gb-font-202 {
    font-size: 109pt !important;
  }
  .gb-font-203 {
    font-size: 109pt !important;
  }
  .gb-font-204 {
    font-size: 110pt !important;
  }
  .gb-font-205 {
    font-size: 110pt !important;
  }
  .gb-font-206 {
    font-size: 111pt !important;
  }
  .gb-font-207 {
    font-size: 111pt !important;
  }
  .gb-font-208 {
    font-size: 112pt !important;
  }
  .gb-font-209 {
    font-size: 112pt !important;
  }
  .gb-font-210 {
    font-size: 113pt !important;
  }
  .gb-font-211 {
    font-size: 113pt !important;
  }
  .gb-font-212 {
    font-size: 114pt !important;
  }
  .gb-font-213 {
    font-size: 114pt !important;
  }
  .gb-font-214 {
    font-size: 115pt !important;
  }
  .gb-font-215 {
    font-size: 115pt !important;
  }
  .gb-font-216 {
    font-size: 116pt !important;
  }
  .gb-font-217 {
    font-size: 116pt !important;
  }
  .gb-font-218 {
    font-size: 117pt !important;
  }
  .gb-font-219 {
    font-size: 117pt !important;
  }
  .gb-font-220 {
    font-size: 118pt !important;
  }
  .gb-font-221 {
    font-size: 118pt !important;
  }
  .gb-font-222 {
    font-size: 119pt !important;
  }
  .gb-font-223 {
    font-size: 119pt !important;
  }
  .gb-font-224 {
    font-size: 120pt !important;
  }
  .gb-font-225 {
    font-size: 120pt !important;
  }
  .gb-font-226 {
    font-size: 121pt !important;
  }
  .gb-font-227 {
    font-size: 121pt !important;
  }
  .gb-font-228 {
    font-size: 122pt !important;
  }
  .gb-font-229 {
    font-size: 122pt !important;
  }
  .gb-font-230 {
    font-size: 123pt !important;
  }
  .gb-font-231 {
    font-size: 123pt !important;
  }
  .gb-font-232 {
    font-size: 124pt !important;
  }
  .gb-font-233 {
    font-size: 124pt !important;
  }
  .gb-font-234 {
    font-size: 125pt !important;
  }
  .gb-font-235 {
    font-size: 125pt !important;
  }
  .gb-font-236 {
    font-size: 126pt !important;
  }
  .gb-font-237 {
    font-size: 126pt !important;
  }
  .gb-font-238 {
    font-size: 127pt !important;
  }
  .gb-font-239 {
    font-size: 127pt !important;
  }
  .gb-font-240 {
    font-size: 128pt !important;
  }
  .gb-font-241 {
    font-size: 128pt !important;
  }
  .gb-font-242 {
    font-size: 129pt !important;
  }
  .gb-font-243 {
    font-size: 129pt !important;
  }
  .gb-font-244 {
    font-size: 130pt !important;
  }
  .gb-font-245 {
    font-size: 130pt !important;
  }
  .gb-font-246 {
    font-size: 131pt !important;
  }
  .gb-font-247 {
    font-size: 131pt !important;
  }
  .gb-font-248 {
    font-size: 132pt !important;
  }
  .gb-font-249 {
    font-size: 132pt !important;
  }
  .gb-font-250 {
    font-size: 133pt !important;
  }
  .gb-font-251 {
    font-size: 133pt !important;
  }
  .gb-font-252 {
    font-size: 134pt !important;
  }
  .gb-font-253 {
    font-size: 134pt !important;
  }
  .gb-font-254 {
    font-size: 135pt !important;
  }
  .gb-font-255 {
    font-size: 135pt !important;
  }
  .gb-font-256 {
    font-size: 136pt !important;
  }
  .gb-font-257 {
    font-size: 136pt !important;
  }
  .gb-font-258 {
    font-size: 137pt !important;
  }
  .gb-font-259 {
    font-size: 137pt !important;
  }
  .gb-font-260 {
    font-size: 138pt !important;
  }
  .gb-font-261 {
    font-size: 138pt !important;
  }
  .gb-font-262 {
    font-size: 139pt !important;
  }
  .gb-font-263 {
    font-size: 139pt !important;
  }
  .gb-font-264 {
    font-size: 140pt !important;
  }
  .gb-font-265 {
    font-size: 140pt !important;
  }
  .gb-font-266 {
    font-size: 141pt !important;
  }
  .gb-font-267 {
    font-size: 141pt !important;
  }
  .gb-font-268 {
    font-size: 142pt !important;
  }
  .gb-font-269 {
    font-size: 142pt !important;
  }
  .gb-font-270 {
    font-size: 143pt !important;
  }
  .gb-font-271 {
    font-size: 143pt !important;
  }
  .gb-font-272 {
    font-size: 144pt !important;
  }
  .gb-font-273 {
    font-size: 144pt !important;
  }
  .gb-font-274 {
    font-size: 145pt !important;
  }
  .gb-font-275 {
    font-size: 145pt !important;
  }
  .gb-font-276 {
    font-size: 146pt !important;
  }
  .gb-font-277 {
    font-size: 146pt !important;
  }
  .gb-font-278 {
    font-size: 147pt !important;
  }
  .gb-font-279 {
    font-size: 147pt !important;
  }
  .gb-font-280 {
    font-size: 148pt !important;
  }
  .gb-font-281 {
    font-size: 148pt !important;
  }
  .gb-font-282 {
    font-size: 149pt !important;
  }
  .gb-font-283 {
    font-size: 149pt !important;
  }
  .gb-font-284 {
    font-size: 150pt !important;
  }
  .gb-font-285 {
    font-size: 150pt !important;
  }
  .gb-font-286 {
    font-size: 151pt !important;
  }
  .gb-font-287 {
    font-size: 151pt !important;
  }
  .gb-font-288 {
    font-size: 152pt !important;
  }
  .gb-font-289 {
    font-size: 152pt !important;
  }
  .gb-font-290 {
    font-size: 153pt !important;
  }
  .gb-font-291 {
    font-size: 153pt !important;
  }
  .gb-font-292 {
    font-size: 154pt !important;
  }
  .gb-font-293 {
    font-size: 154pt !important;
  }
  .gb-font-294 {
    font-size: 155pt !important;
  }
  .gb-font-295 {
    font-size: 155pt !important;
  }
  .gb-font-296 {
    font-size: 156pt !important;
  }
  .gb-font-297 {
    font-size: 156pt !important;
  }
  .gb-font-298 {
    font-size: 157pt !important;
  }
  .gb-font-299 {
    font-size: 157pt !important;
  }
  .gb-font-300 {
    font-size: 158pt !important;
  }
  .gb-font-301 {
    font-size: 158pt !important;
  }
  .gb-font-302 {
    font-size: 159pt !important;
  }
  .gb-font-303 {
    font-size: 159pt !important;
  }
  .gb-font-304 {
    font-size: 160pt !important;
  }
  .gb-font-305 {
    font-size: 160pt !important;
  }
  .gb-font-306 {
    font-size: 161pt !important;
  }
  .gb-font-307 {
    font-size: 161pt !important;
  }
  .gb-font-308 {
    font-size: 162pt !important;
  }
  .gb-font-309 {
    font-size: 162pt !important;
  }
  .gb-font-310 {
    font-size: 163pt !important;
  }
  .gb-font-311 {
    font-size: 163pt !important;
  }
  .gb-font-312 {
    font-size: 164pt !important;
  }
  .gb-font-313 {
    font-size: 164pt !important;
  }
  .gb-font-314 {
    font-size: 165pt !important;
  }
  .gb-font-315 {
    font-size: 165pt !important;
  }
  .gb-font-316 {
    font-size: 166pt !important;
  }
  .gb-font-317 {
    font-size: 166pt !important;
  }
  .gb-font-318 {
    font-size: 167pt !important;
  }
  .gb-font-319 {
    font-size: 167pt !important;
  }
  .gb-font-320 {
    font-size: 168pt !important;
  }
  .gb-font-321 {
    font-size: 168pt !important;
  }
  .gb-font-322 {
    font-size: 169pt !important;
  }
  .gb-font-323 {
    font-size: 169pt !important;
  }
  .gb-font-324 {
    font-size: 170pt !important;
  }
  .gb-font-325 {
    font-size: 170pt !important;
  }
  .gb-font-326 {
    font-size: 171pt !important;
  }
  .gb-font-327 {
    font-size: 171pt !important;
  }
  .gb-font-328 {
    font-size: 172pt !important;
  }
  .gb-font-329 {
    font-size: 172pt !important;
  }
  .gb-font-330 {
    font-size: 173pt !important;
  }
  .gb-font-331 {
    font-size: 173pt !important;
  }
  .gb-font-332 {
    font-size: 174pt !important;
  }
  .gb-font-333 {
    font-size: 174pt !important;
  }
  .gb-font-334 {
    font-size: 175pt !important;
  }
  .gb-font-335 {
    font-size: 175pt !important;
  }
  .gb-font-336 {
    font-size: 176pt !important;
  }
  .gb-font-337 {
    font-size: 176pt !important;
  }
  .gb-font-338 {
    font-size: 177pt !important;
  }
  .gb-font-339 {
    font-size: 177pt !important;
  }
  .gb-font-340 {
    font-size: 178pt !important;
  }
  .gb-font-341 {
    font-size: 178pt !important;
  }
  .gb-font-342 {
    font-size: 179pt !important;
  }
  .gb-font-343 {
    font-size: 179pt !important;
  }
  .gb-font-344 {
    font-size: 180pt !important;
  }
  .gb-font-345 {
    font-size: 180pt !important;
  }
  .gb-font-346 {
    font-size: 181pt !important;
  }
  .gb-font-347 {
    font-size: 181pt !important;
  }
  .gb-font-348 {
    font-size: 182pt !important;
  }
  .gb-font-349 {
    font-size: 182pt !important;
  }
  .gb-font-350 {
    font-size: 183pt !important;
  }
  .gb-font-351 {
    font-size: 183pt !important;
  }
  .gb-font-352 {
    font-size: 184pt !important;
  }
  .gb-font-353 {
    font-size: 184pt !important;
  }
  .gb-font-354 {
    font-size: 185pt !important;
  }
  .gb-font-355 {
    font-size: 185pt !important;
  }
  .gb-font-356 {
    font-size: 186pt !important;
  }
  .gb-font-357 {
    font-size: 186pt !important;
  }
  .gb-font-358 {
    font-size: 187pt !important;
  }
  .gb-font-359 {
    font-size: 187pt !important;
  }
  .gb-font-360 {
    font-size: 188pt !important;
  }
  .gb-font-361 {
    font-size: 188pt !important;
  }
  .gb-font-362 {
    font-size: 189pt !important;
  }
  .gb-font-363 {
    font-size: 189pt !important;
  }
  .gb-font-364 {
    font-size: 190pt !important;
  }
  .gb-font-365 {
    font-size: 190pt !important;
  }
  .gb-font-366 {
    font-size: 191pt !important;
  }
  .gb-font-367 {
    font-size: 191pt !important;
  }
  .gb-font-368 {
    font-size: 192pt !important;
  }
  .gb-font-369 {
    font-size: 192pt !important;
  }
  .gb-font-370 {
    font-size: 193pt !important;
  }
  .gb-font-371 {
    font-size: 193pt !important;
  }
  .gb-font-372 {
    font-size: 194pt !important;
  }
  .gb-font-373 {
    font-size: 194pt !important;
  }
  .gb-font-374 {
    font-size: 195pt !important;
  }
  .gb-font-375 {
    font-size: 195pt !important;
  }
  .gb-font-376 {
    font-size: 196pt !important;
  }
  .gb-font-377 {
    font-size: 196pt !important;
  }
  .gb-font-378 {
    font-size: 197pt !important;
  }
  .gb-font-379 {
    font-size: 197pt !important;
  }
  .gb-font-380 {
    font-size: 198pt !important;
  }
  .gb-font-381 {
    font-size: 198pt !important;
  }
  .gb-font-382 {
    font-size: 199pt !important;
  }
  .gb-font-383 {
    font-size: 199pt !important;
  }
  .gb-font-384 {
    font-size: 200pt !important;
  }
  .gb-font-385 {
    font-size: 200pt !important;
  }
  .gb-font-386 {
    font-size: 201pt !important;
  }
  .gb-font-387 {
    font-size: 201pt !important;
  }
  .gb-font-388 {
    font-size: 202pt !important;
  }
  .gb-font-389 {
    font-size: 202pt !important;
  }
  .gb-font-390 {
    font-size: 203pt !important;
  }
  .gb-font-391 {
    font-size: 203pt !important;
  }
  .gb-font-392 {
    font-size: 204pt !important;
  }
  .gb-font-393 {
    font-size: 204pt !important;
  }
  .gb-font-394 {
    font-size: 205pt !important;
  }
  .gb-font-395 {
    font-size: 205pt !important;
  }
  .gb-font-396 {
    font-size: 206pt !important;
  }
  .gb-font-397 {
    font-size: 206pt !important;
  }
  .gb-font-398 {
    font-size: 207pt !important;
  }
  .gb-font-399 {
    font-size: 207pt !important;
  }
  .gb-font-400 {
    font-size: 208pt !important;
  }
  .gb-font-401 {
    font-size: 208pt !important;
  }
  .gb-font-402 {
    font-size: 209pt !important;
  }
  .gb-font-403 {
    font-size: 209pt !important;
  }
  .gb-font-404 {
    font-size: 210pt !important;
  }
  .gb-font-405 {
    font-size: 210pt !important;
  }
  .gb-font-406 {
    font-size: 211pt !important;
  }
  .gb-font-407 {
    font-size: 211pt !important;
  }
  .gb-font-408 {
    font-size: 212pt !important;
  }
  .gb-font-409 {
    font-size: 212pt !important;
  }
  .gb-font-410 {
    font-size: 213pt !important;
  }
  .gb-font-411 {
    font-size: 213pt !important;
  }
  .gb-font-412 {
    font-size: 214pt !important;
  }
  .gb-font-413 {
    font-size: 214pt !important;
  }
  .gb-font-414 {
    font-size: 215pt !important;
  }
  .gb-font-415 {
    font-size: 215pt !important;
  }
  .gb-font-416 {
    font-size: 216pt !important;
  }
  .gb-font-417 {
    font-size: 216pt !important;
  }
  .gb-font-418 {
    font-size: 217pt !important;
  }
  .gb-font-419 {
    font-size: 217pt !important;
  }
  .gb-font-420 {
    font-size: 218pt !important;
  }
  .gb-font-421 {
    font-size: 218pt !important;
  }
  .gb-font-422 {
    font-size: 219pt !important;
  }
  .gb-font-423 {
    font-size: 219pt !important;
  }
  .gb-font-424 {
    font-size: 220pt !important;
  }
  .gb-font-425 {
    font-size: 220pt !important;
  }
  .gb-font-426 {
    font-size: 221pt !important;
  }
  .gb-font-427 {
    font-size: 221pt !important;
  }
  .gb-font-428 {
    font-size: 222pt !important;
  }
  .gb-font-429 {
    font-size: 222pt !important;
  }
  .gb-font-430 {
    font-size: 223pt !important;
  }
  .gb-font-431 {
    font-size: 223pt !important;
  }
  .gb-font-432 {
    font-size: 224pt !important;
  }
  .gb-font-433 {
    font-size: 224pt !important;
  }
  .gb-font-434 {
    font-size: 225pt !important;
  }
  .gb-font-435 {
    font-size: 225pt !important;
  }
  .gb-font-436 {
    font-size: 226pt !important;
  }
  .gb-font-437 {
    font-size: 226pt !important;
  }
  .gb-font-438 {
    font-size: 227pt !important;
  }
  .gb-font-439 {
    font-size: 227pt !important;
  }
  .gb-font-440 {
    font-size: 228pt !important;
  }
  .gb-font-441 {
    font-size: 228pt !important;
  }
  .gb-font-442 {
    font-size: 229pt !important;
  }
  .gb-font-443 {
    font-size: 229pt !important;
  }
  .gb-font-444 {
    font-size: 230pt !important;
  }
  .gb-font-445 {
    font-size: 230pt !important;
  }
  .gb-font-446 {
    font-size: 231pt !important;
  }
  .gb-font-447 {
    font-size: 231pt !important;
  }
  .gb-font-448 {
    font-size: 232pt !important;
  }
  .gb-font-449 {
    font-size: 232pt !important;
  }
  .gb-font-450 {
    font-size: 233pt !important;
  }
  .gb-font-451 {
    font-size: 233pt !important;
  }
  .gb-font-452 {
    font-size: 234pt !important;
  }
  .gb-font-453 {
    font-size: 234pt !important;
  }
  .gb-font-454 {
    font-size: 235pt !important;
  }
  .gb-font-455 {
    font-size: 235pt !important;
  }
  .gb-font-456 {
    font-size: 236pt !important;
  }
  .gb-font-457 {
    font-size: 236pt !important;
  }
  .gb-font-458 {
    font-size: 237pt !important;
  }
  .gb-font-459 {
    font-size: 237pt !important;
  }
  .gb-font-460 {
    font-size: 238pt !important;
  }
  .gb-font-461 {
    font-size: 238pt !important;
  }
  .gb-font-462 {
    font-size: 239pt !important;
  }
  .gb-font-463 {
    font-size: 239pt !important;
  }
  .gb-font-464 {
    font-size: 240pt !important;
  }
  .gb-font-465 {
    font-size: 240pt !important;
  }
  .gb-font-466 {
    font-size: 241pt !important;
  }
  .gb-font-467 {
    font-size: 241pt !important;
  }
  .gb-font-468 {
    font-size: 242pt !important;
  }
  .gb-font-469 {
    font-size: 242pt !important;
  }
  .gb-font-470 {
    font-size: 243pt !important;
  }
  .gb-font-471 {
    font-size: 243pt !important;
  }
  .gb-font-472 {
    font-size: 244pt !important;
  }
  .gb-font-473 {
    font-size: 244pt !important;
  }
  .gb-font-474 {
    font-size: 245pt !important;
  }
  .gb-font-475 {
    font-size: 245pt !important;
  }
  .gb-font-476 {
    font-size: 246pt !important;
  }
  .gb-font-477 {
    font-size: 246pt !important;
  }
  .gb-font-478 {
    font-size: 247pt !important;
  }
  .gb-font-479 {
    font-size: 247pt !important;
  }
  .gb-font-480 {
    font-size: 248pt !important;
  }
  .gb-font-481 {
    font-size: 248pt !important;
  }
  .gb-font-482 {
    font-size: 249pt !important;
  }
  .gb-font-483 {
    font-size: 249pt !important;
  }
  .gb-font-484 {
    font-size: 250pt !important;
  }
  .gb-font-485 {
    font-size: 250pt !important;
  }
  .gb-font-486 {
    font-size: 251pt !important;
  }
  .gb-font-487 {
    font-size: 251pt !important;
  }
  .gb-font-488 {
    font-size: 252pt !important;
  }
  .gb-font-489 {
    font-size: 252pt !important;
  }
  .gb-font-490 {
    font-size: 253pt !important;
  }
  .gb-font-491 {
    font-size: 253pt !important;
  }
  .gb-font-492 {
    font-size: 254pt !important;
  }
  .gb-font-493 {
    font-size: 254pt !important;
  }
  .gb-font-494 {
    font-size: 255pt !important;
  }
  .gb-font-495 {
    font-size: 255pt !important;
  }
  .gb-font-496 {
    font-size: 256pt !important;
  }
  .gb-font-497 {
    font-size: 256pt !important;
  }
  .gb-font-498 {
    font-size: 257pt !important;
  }
  .gb-font-499 {
    font-size: 257pt !important;
  }
  .gb-font-500 {
    font-size: 258pt !important;
  }
}

/* FONT-SIZE MEDIUM */
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .gb-font-18 {
    font-size: 17pt !important;
  }
  .gb-font-19 {
    font-size: 18pt !important;
  }
  .gb-font-20 {
    font-size: 19pt !important;
  }
  .gb-font-21 {
    font-size: 20pt !important;
  }
  .gb-font-22 {
    font-size: 21pt !important;
  }
  .gb-font-23 {
    font-size: 22pt !important;
  }
  .gb-font-24 {
    font-size: 22pt !important;
  }
  .gb-font-25 {
    font-size: 23pt !important;
  }
  .gb-font-26 {
    font-size: 24pt !important;
  }
  .gb-font-27 {
    font-size: 25pt !important;
  }
  .gb-font-28 {
    font-size: 26pt !important;
  }
  .gb-font-29 {
    font-size: 26pt !important;
  }
  .gb-font-30 {
    font-size: 27pt !important;
  }
  .gb-font-31 {
    font-size: 28pt !important;
  }
  .gb-font-32 {
    font-size: 29pt !important;
  }
  .gb-font-33 {
    font-size: 30pt !important;
  }
  .gb-font-34 {
    font-size: 30pt !important;
  }
  .gb-font-35 {
    font-size: 31pt !important;
  }
  .gb-font-36 {
    font-size: 32pt !important;
  }
  .gb-font-37 {
    font-size: 33pt !important;
  }
  .gb-font-38 {
    font-size: 34pt !important;
  }
  .gb-font-39 {
    font-size: 34pt !important;
  }
  .gb-font-40 {
    font-size: 35pt !important;
  }
  .gb-font-41 {
    font-size: 36pt !important;
  }
  .gb-font-42 {
    font-size: 37pt !important;
  }
  .gb-font-43 {
    font-size: 38pt !important;
  }
  .gb-font-44 {
    font-size: 38pt !important;
  }
  .gb-font-45 {
    font-size: 39pt !important;
  }
  .gb-font-46 {
    font-size: 40pt !important;
  }
  .gb-font-47 {
    font-size: 41pt !important;
  }
  .gb-font-48 {
    font-size: 42pt !important;
  }
  .gb-font-49 {
    font-size: 42pt !important;
  }
  .gb-font-50 {
    font-size: 43pt !important;
  }
  .gb-font-51 {
    font-size: 44pt !important;
  }
  .gb-font-52 {
    font-size: 45pt !important;
  }
  .gb-font-53 {
    font-size: 46pt !important;
  }
  .gb-font-54 {
    font-size: 46pt !important;
  }
  .gb-font-55 {
    font-size: 47pt !important;
  }
  .gb-font-56 {
    font-size: 48pt !important;
  }
  .gb-font-57 {
    font-size: 49pt !important;
  }
  .gb-font-58 {
    font-size: 50pt !important;
  }
  .gb-font-59 {
    font-size: 50pt !important;
  }
  .gb-font-60 {
    font-size: 51pt !important;
  }
  .gb-font-61 {
    font-size: 52pt !important;
  }
  .gb-font-62 {
    font-size: 53pt !important;
  }
  .gb-font-63 {
    font-size: 54pt !important;
  }
  .gb-font-64 {
    font-size: 54pt !important;
  }
  .gb-font-65 {
    font-size: 55pt !important;
  }
  .gb-font-66 {
    font-size: 56pt !important;
  }
  .gb-font-67 {
    font-size: 57pt !important;
  }
  .gb-font-68 {
    font-size: 58pt !important;
  }
  .gb-font-69 {
    font-size: 58pt !important;
  }
  .gb-font-70 {
    font-size: 59pt !important;
  }
  .gb-font-71 {
    font-size: 60pt !important;
  }
  .gb-font-72 {
    font-size: 61pt !important;
  }
  .gb-font-73 {
    font-size: 62pt !important;
  }
  .gb-font-74 {
    font-size: 62pt !important;
  }
  .gb-font-75 {
    font-size: 63pt !important;
  }
  .gb-font-76 {
    font-size: 64pt !important;
  }
  .gb-font-77 {
    font-size: 65pt !important;
  }
  .gb-font-78 {
    font-size: 66pt !important;
  }
  .gb-font-79 {
    font-size: 66pt !important;
  }
  .gb-font-80 {
    font-size: 67pt !important;
  }
  .gb-font-81 {
    font-size: 68pt !important;
  }
  .gb-font-82 {
    font-size: 69pt !important;
  }
  .gb-font-83 {
    font-size: 70pt !important;
  }
  .gb-font-84 {
    font-size: 70pt !important;
  }
  .gb-font-85 {
    font-size: 71pt !important;
  }
  .gb-font-86 {
    font-size: 72pt !important;
  }
  .gb-font-87 {
    font-size: 73pt !important;
  }
  .gb-font-88 {
    font-size: 74pt !important;
  }
  .gb-font-89 {
    font-size: 74pt !important;
  }
  .gb-font-90 {
    font-size: 75pt !important;
  }
  .gb-font-91 {
    font-size: 76pt !important;
  }
  .gb-font-92 {
    font-size: 77pt !important;
  }
  .gb-font-93 {
    font-size: 78pt !important;
  }
  .gb-font-94 {
    font-size: 78pt !important;
  }
  .gb-font-95 {
    font-size: 79pt !important;
  }
  .gb-font-96 {
    font-size: 80pt !important;
  }
  .gb-font-97 {
    font-size: 81pt !important;
  }
  .gb-font-98 {
    font-size: 82pt !important;
  }
  .gb-font-99 {
    font-size: 82pt !important;
  }
  .gb-font-100 {
    font-size: 83pt !important;
  }
  .gb-font-101 {
    font-size: 84pt !important;
  }
  .gb-font-102 {
    font-size: 85pt !important;
  }
  .gb-font-103 {
    font-size: 86pt !important;
  }
  .gb-font-104 {
    font-size: 86pt !important;
  }
  .gb-font-105 {
    font-size: 87pt !important;
  }
  .gb-font-106 {
    font-size: 88pt !important;
  }
  .gb-font-107 {
    font-size: 89pt !important;
  }
  .gb-font-108 {
    font-size: 90pt !important;
  }
  .gb-font-109 {
    font-size: 90pt !important;
  }
  .gb-font-110 {
    font-size: 91pt !important;
  }
  .gb-font-111 {
    font-size: 92pt !important;
  }
  .gb-font-112 {
    font-size: 93pt !important;
  }
  .gb-font-113 {
    font-size: 94pt !important;
  }
  .gb-font-114 {
    font-size: 94pt !important;
  }
  .gb-font-115 {
    font-size: 95pt !important;
  }
  .gb-font-116 {
    font-size: 96pt !important;
  }
  .gb-font-117 {
    font-size: 97pt !important;
  }
  .gb-font-118 {
    font-size: 98pt !important;
  }
  .gb-font-119 {
    font-size: 98pt !important;
  }
  .gb-font-120 {
    font-size: 99pt !important;
  }
  .gb-font-121 {
    font-size: 100pt !important;
  }
  .gb-font-122 {
    font-size: 101pt !important;
  }
  .gb-font-123 {
    font-size: 102pt !important;
  }
  .gb-font-124 {
    font-size: 102pt !important;
  }
  .gb-font-125 {
    font-size: 103pt !important;
  }
  .gb-font-126 {
    font-size: 104pt !important;
  }
  .gb-font-127 {
    font-size: 105pt !important;
  }
  .gb-font-128 {
    font-size: 106pt !important;
  }
  .gb-font-129 {
    font-size: 106pt !important;
  }
  .gb-font-130 {
    font-size: 107pt !important;
  }
  .gb-font-131 {
    font-size: 108pt !important;
  }
  .gb-font-132 {
    font-size: 109pt !important;
  }
  .gb-font-133 {
    font-size: 110pt !important;
  }
  .gb-font-134 {
    font-size: 110pt !important;
  }
  .gb-font-135 {
    font-size: 111pt !important;
  }
  .gb-font-136 {
    font-size: 112pt !important;
  }
  .gb-font-137 {
    font-size: 113pt !important;
  }
  .gb-font-138 {
    font-size: 114pt !important;
  }
  .gb-font-139 {
    font-size: 114pt !important;
  }
  .gb-font-140 {
    font-size: 115pt !important;
  }
  .gb-font-141 {
    font-size: 116pt !important;
  }
  .gb-font-142 {
    font-size: 117pt !important;
  }
  .gb-font-143 {
    font-size: 118pt !important;
  }
  .gb-font-144 {
    font-size: 118pt !important;
  }
  .gb-font-145 {
    font-size: 119pt !important;
  }
  .gb-font-146 {
    font-size: 120pt !important;
  }
  .gb-font-147 {
    font-size: 121pt !important;
  }
  .gb-font-148 {
    font-size: 122pt !important;
  }
  .gb-font-149 {
    font-size: 122pt !important;
  }
  .gb-font-150 {
    font-size: 123pt !important;
  }
  .gb-font-151 {
    font-size: 124pt !important;
  }
  .gb-font-152 {
    font-size: 125pt !important;
  }
  .gb-font-153 {
    font-size: 126pt !important;
  }
  .gb-font-154 {
    font-size: 126pt !important;
  }
  .gb-font-155 {
    font-size: 127pt !important;
  }
  .gb-font-156 {
    font-size: 128pt !important;
  }
  .gb-font-157 {
    font-size: 129pt !important;
  }
  .gb-font-158 {
    font-size: 130pt !important;
  }
  .gb-font-159 {
    font-size: 130pt !important;
  }
  .gb-font-160 {
    font-size: 131pt !important;
  }
  .gb-font-161 {
    font-size: 132pt !important;
  }
  .gb-font-162 {
    font-size: 133pt !important;
  }
  .gb-font-163 {
    font-size: 134pt !important;
  }
  .gb-font-164 {
    font-size: 134pt !important;
  }
  .gb-font-165 {
    font-size: 135pt !important;
  }
  .gb-font-166 {
    font-size: 136pt !important;
  }
  .gb-font-167 {
    font-size: 137pt !important;
  }
  .gb-font-168 {
    font-size: 138pt !important;
  }
  .gb-font-169 {
    font-size: 138pt !important;
  }
  .gb-font-170 {
    font-size: 139pt !important;
  }
  .gb-font-171 {
    font-size: 140pt !important;
  }
  .gb-font-172 {
    font-size: 141pt !important;
  }
  .gb-font-173 {
    font-size: 142pt !important;
  }
  .gb-font-174 {
    font-size: 142pt !important;
  }
  .gb-font-175 {
    font-size: 143pt !important;
  }
  .gb-font-176 {
    font-size: 144pt !important;
  }
  .gb-font-177 {
    font-size: 145pt !important;
  }
  .gb-font-178 {
    font-size: 146pt !important;
  }
  .gb-font-179 {
    font-size: 146pt !important;
  }
  .gb-font-180 {
    font-size: 147pt !important;
  }
  .gb-font-181 {
    font-size: 148pt !important;
  }
  .gb-font-182 {
    font-size: 149pt !important;
  }
  .gb-font-183 {
    font-size: 150pt !important;
  }
  .gb-font-184 {
    font-size: 150pt !important;
  }
  .gb-font-185 {
    font-size: 151pt !important;
  }
  .gb-font-186 {
    font-size: 152pt !important;
  }
  .gb-font-187 {
    font-size: 153pt !important;
  }
  .gb-font-188 {
    font-size: 154pt !important;
  }
  .gb-font-189 {
    font-size: 154pt !important;
  }
  .gb-font-190 {
    font-size: 155pt !important;
  }
  .gb-font-191 {
    font-size: 156pt !important;
  }
  .gb-font-192 {
    font-size: 157pt !important;
  }
  .gb-font-193 {
    font-size: 158pt !important;
  }
  .gb-font-194 {
    font-size: 158pt !important;
  }
  .gb-font-195 {
    font-size: 159pt !important;
  }
  .gb-font-196 {
    font-size: 160pt !important;
  }
  .gb-font-197 {
    font-size: 161pt !important;
  }
  .gb-font-198 {
    font-size: 162pt !important;
  }
  .gb-font-199 {
    font-size: 162pt !important;
  }
  .gb-font-200 {
    font-size: 163pt !important;
  }
  .gb-font-201 {
    font-size: 164pt !important;
  }
  .gb-font-202 {
    font-size: 165pt !important;
  }
  .gb-font-203 {
    font-size: 166pt !important;
  }
  .gb-font-204 {
    font-size: 166pt !important;
  }
  .gb-font-205 {
    font-size: 167pt !important;
  }
  .gb-font-206 {
    font-size: 168pt !important;
  }
  .gb-font-207 {
    font-size: 169pt !important;
  }
  .gb-font-208 {
    font-size: 170pt !important;
  }
  .gb-font-209 {
    font-size: 170pt !important;
  }
  .gb-font-210 {
    font-size: 171pt !important;
  }
  .gb-font-211 {
    font-size: 172pt !important;
  }
  .gb-font-212 {
    font-size: 173pt !important;
  }
  .gb-font-213 {
    font-size: 174pt !important;
  }
  .gb-font-214 {
    font-size: 174pt !important;
  }
  .gb-font-215 {
    font-size: 175pt !important;
  }
  .gb-font-216 {
    font-size: 176pt !important;
  }
  .gb-font-217 {
    font-size: 177pt !important;
  }
  .gb-font-218 {
    font-size: 178pt !important;
  }
  .gb-font-219 {
    font-size: 178pt !important;
  }
  .gb-font-220 {
    font-size: 179pt !important;
  }
  .gb-font-221 {
    font-size: 180pt !important;
  }
  .gb-font-222 {
    font-size: 181pt !important;
  }
  .gb-font-223 {
    font-size: 182pt !important;
  }
  .gb-font-224 {
    font-size: 182pt !important;
  }
  .gb-font-225 {
    font-size: 183pt !important;
  }
  .gb-font-226 {
    font-size: 184pt !important;
  }
  .gb-font-227 {
    font-size: 185pt !important;
  }
  .gb-font-228 {
    font-size: 186pt !important;
  }
  .gb-font-229 {
    font-size: 186pt !important;
  }
  .gb-font-230 {
    font-size: 187pt !important;
  }
  .gb-font-231 {
    font-size: 188pt !important;
  }
  .gb-font-232 {
    font-size: 189pt !important;
  }
  .gb-font-233 {
    font-size: 190pt !important;
  }
  .gb-font-234 {
    font-size: 190pt !important;
  }
  .gb-font-235 {
    font-size: 191pt !important;
  }
  .gb-font-236 {
    font-size: 192pt !important;
  }
  .gb-font-237 {
    font-size: 193pt !important;
  }
  .gb-font-238 {
    font-size: 194pt !important;
  }
  .gb-font-239 {
    font-size: 194pt !important;
  }
  .gb-font-240 {
    font-size: 195pt !important;
  }
  .gb-font-241 {
    font-size: 196pt !important;
  }
  .gb-font-242 {
    font-size: 197pt !important;
  }
  .gb-font-243 {
    font-size: 198pt !important;
  }
  .gb-font-244 {
    font-size: 198pt !important;
  }
  .gb-font-245 {
    font-size: 199pt !important;
  }
  .gb-font-246 {
    font-size: 200pt !important;
  }
  .gb-font-247 {
    font-size: 201pt !important;
  }
  .gb-font-248 {
    font-size: 202pt !important;
  }
  .gb-font-249 {
    font-size: 202pt !important;
  }
  .gb-font-250 {
    font-size: 203pt !important;
  }
  .gb-font-251 {
    font-size: 204pt !important;
  }
  .gb-font-252 {
    font-size: 205pt !important;
  }
  .gb-font-253 {
    font-size: 206pt !important;
  }
  .gb-font-254 {
    font-size: 206pt !important;
  }
  .gb-font-255 {
    font-size: 207pt !important;
  }
  .gb-font-256 {
    font-size: 208pt !important;
  }
  .gb-font-257 {
    font-size: 209pt !important;
  }
  .gb-font-258 {
    font-size: 210pt !important;
  }
  .gb-font-259 {
    font-size: 210pt !important;
  }
  .gb-font-260 {
    font-size: 211pt !important;
  }
  .gb-font-261 {
    font-size: 212pt !important;
  }
  .gb-font-262 {
    font-size: 213pt !important;
  }
  .gb-font-263 {
    font-size: 214pt !important;
  }
  .gb-font-264 {
    font-size: 214pt !important;
  }
  .gb-font-265 {
    font-size: 215pt !important;
  }
  .gb-font-266 {
    font-size: 216pt !important;
  }
  .gb-font-267 {
    font-size: 217pt !important;
  }
  .gb-font-268 {
    font-size: 218pt !important;
  }
  .gb-font-269 {
    font-size: 218pt !important;
  }
  .gb-font-270 {
    font-size: 219pt !important;
  }
  .gb-font-271 {
    font-size: 220pt !important;
  }
  .gb-font-272 {
    font-size: 221pt !important;
  }
  .gb-font-273 {
    font-size: 222pt !important;
  }
  .gb-font-274 {
    font-size: 222pt !important;
  }
  .gb-font-275 {
    font-size: 223pt !important;
  }
  .gb-font-276 {
    font-size: 224pt !important;
  }
  .gb-font-277 {
    font-size: 225pt !important;
  }
  .gb-font-278 {
    font-size: 226pt !important;
  }
  .gb-font-279 {
    font-size: 226pt !important;
  }
  .gb-font-280 {
    font-size: 227pt !important;
  }
  .gb-font-281 {
    font-size: 228pt !important;
  }
  .gb-font-282 {
    font-size: 229pt !important;
  }
  .gb-font-283 {
    font-size: 230pt !important;
  }
  .gb-font-284 {
    font-size: 230pt !important;
  }
  .gb-font-285 {
    font-size: 231pt !important;
  }
  .gb-font-286 {
    font-size: 232pt !important;
  }
  .gb-font-287 {
    font-size: 233pt !important;
  }
  .gb-font-288 {
    font-size: 234pt !important;
  }
  .gb-font-289 {
    font-size: 234pt !important;
  }
  .gb-font-290 {
    font-size: 235pt !important;
  }
  .gb-font-291 {
    font-size: 236pt !important;
  }
  .gb-font-292 {
    font-size: 237pt !important;
  }
  .gb-font-293 {
    font-size: 238pt !important;
  }
  .gb-font-294 {
    font-size: 238pt !important;
  }
  .gb-font-295 {
    font-size: 239pt !important;
  }
  .gb-font-296 {
    font-size: 240pt !important;
  }
  .gb-font-297 {
    font-size: 241pt !important;
  }
  .gb-font-298 {
    font-size: 242pt !important;
  }
  .gb-font-299 {
    font-size: 242pt !important;
  }
  .gb-font-300 {
    font-size: 243pt !important;
  }
  .gb-font-301 {
    font-size: 244pt !important;
  }
  .gb-font-302 {
    font-size: 245pt !important;
  }
  .gb-font-303 {
    font-size: 246pt !important;
  }
  .gb-font-304 {
    font-size: 246pt !important;
  }
  .gb-font-305 {
    font-size: 247pt !important;
  }
  .gb-font-306 {
    font-size: 248pt !important;
  }
  .gb-font-307 {
    font-size: 249pt !important;
  }
  .gb-font-308 {
    font-size: 250pt !important;
  }
  .gb-font-309 {
    font-size: 250pt !important;
  }
  .gb-font-310 {
    font-size: 251pt !important;
  }
  .gb-font-311 {
    font-size: 252pt !important;
  }
  .gb-font-312 {
    font-size: 253pt !important;
  }
  .gb-font-313 {
    font-size: 254pt !important;
  }
  .gb-font-314 {
    font-size: 254pt !important;
  }
  .gb-font-315 {
    font-size: 255pt !important;
  }
  .gb-font-316 {
    font-size: 256pt !important;
  }
  .gb-font-317 {
    font-size: 257pt !important;
  }
  .gb-font-318 {
    font-size: 258pt !important;
  }
  .gb-font-319 {
    font-size: 258pt !important;
  }
  .gb-font-320 {
    font-size: 259pt !important;
  }
  .gb-font-321 {
    font-size: 260pt !important;
  }
  .gb-font-322 {
    font-size: 261pt !important;
  }
  .gb-font-323 {
    font-size: 262pt !important;
  }
  .gb-font-324 {
    font-size: 262pt !important;
  }
  .gb-font-325 {
    font-size: 263pt !important;
  }
  .gb-font-326 {
    font-size: 264pt !important;
  }
  .gb-font-327 {
    font-size: 265pt !important;
  }
  .gb-font-328 {
    font-size: 266pt !important;
  }
  .gb-font-329 {
    font-size: 266pt !important;
  }
  .gb-font-330 {
    font-size: 267pt !important;
  }
  .gb-font-331 {
    font-size: 268pt !important;
  }
  .gb-font-332 {
    font-size: 269pt !important;
  }
  .gb-font-333 {
    font-size: 270pt !important;
  }
  .gb-font-334 {
    font-size: 270pt !important;
  }
  .gb-font-335 {
    font-size: 271pt !important;
  }
  .gb-font-336 {
    font-size: 272pt !important;
  }
  .gb-font-337 {
    font-size: 273pt !important;
  }
  .gb-font-338 {
    font-size: 274pt !important;
  }
  .gb-font-339 {
    font-size: 274pt !important;
  }
  .gb-font-340 {
    font-size: 275pt !important;
  }
  .gb-font-341 {
    font-size: 276pt !important;
  }
  .gb-font-342 {
    font-size: 277pt !important;
  }
  .gb-font-343 {
    font-size: 278pt !important;
  }
  .gb-font-344 {
    font-size: 278pt !important;
  }
  .gb-font-345 {
    font-size: 279pt !important;
  }
  .gb-font-346 {
    font-size: 280pt !important;
  }
  .gb-font-347 {
    font-size: 281pt !important;
  }
  .gb-font-348 {
    font-size: 282pt !important;
  }
  .gb-font-349 {
    font-size: 282pt !important;
  }
  .gb-font-350 {
    font-size: 283pt !important;
  }
  .gb-font-351 {
    font-size: 284pt !important;
  }
  .gb-font-352 {
    font-size: 285pt !important;
  }
  .gb-font-353 {
    font-size: 286pt !important;
  }
  .gb-font-354 {
    font-size: 286pt !important;
  }
  .gb-font-355 {
    font-size: 287pt !important;
  }
  .gb-font-356 {
    font-size: 288pt !important;
  }
  .gb-font-357 {
    font-size: 289pt !important;
  }
  .gb-font-358 {
    font-size: 290pt !important;
  }
  .gb-font-359 {
    font-size: 290pt !important;
  }
  .gb-font-360 {
    font-size: 291pt !important;
  }
  .gb-font-361 {
    font-size: 292pt !important;
  }
  .gb-font-362 {
    font-size: 293pt !important;
  }
  .gb-font-363 {
    font-size: 294pt !important;
  }
  .gb-font-364 {
    font-size: 294pt !important;
  }
  .gb-font-365 {
    font-size: 295pt !important;
  }
  .gb-font-366 {
    font-size: 296pt !important;
  }
  .gb-font-367 {
    font-size: 297pt !important;
  }
  .gb-font-368 {
    font-size: 298pt !important;
  }
  .gb-font-369 {
    font-size: 298pt !important;
  }
  .gb-font-370 {
    font-size: 299pt !important;
  }
  .gb-font-371 {
    font-size: 300pt !important;
  }
  .gb-font-372 {
    font-size: 301pt !important;
  }
  .gb-font-373 {
    font-size: 302pt !important;
  }
  .gb-font-374 {
    font-size: 302pt !important;
  }
  .gb-font-375 {
    font-size: 303pt !important;
  }
  .gb-font-376 {
    font-size: 304pt !important;
  }
  .gb-font-377 {
    font-size: 305pt !important;
  }
  .gb-font-378 {
    font-size: 306pt !important;
  }
  .gb-font-379 {
    font-size: 306pt !important;
  }
  .gb-font-380 {
    font-size: 307pt !important;
  }
  .gb-font-381 {
    font-size: 308pt !important;
  }
  .gb-font-382 {
    font-size: 309pt !important;
  }
  .gb-font-383 {
    font-size: 310pt !important;
  }
  .gb-font-384 {
    font-size: 310pt !important;
  }
  .gb-font-385 {
    font-size: 311pt !important;
  }
  .gb-font-386 {
    font-size: 312pt !important;
  }
  .gb-font-387 {
    font-size: 313pt !important;
  }
  .gb-font-388 {
    font-size: 314pt !important;
  }
  .gb-font-389 {
    font-size: 314pt !important;
  }
  .gb-font-390 {
    font-size: 315pt !important;
  }
  .gb-font-391 {
    font-size: 316pt !important;
  }
  .gb-font-392 {
    font-size: 317pt !important;
  }
  .gb-font-393 {
    font-size: 318pt !important;
  }
  .gb-font-394 {
    font-size: 318pt !important;
  }
  .gb-font-395 {
    font-size: 319pt !important;
  }
  .gb-font-396 {
    font-size: 320pt !important;
  }
  .gb-font-397 {
    font-size: 321pt !important;
  }
  .gb-font-398 {
    font-size: 322pt !important;
  }
  .gb-font-399 {
    font-size: 322pt !important;
  }
  .gb-font-400 {
    font-size: 323pt !important;
  }
  .gb-font-401 {
    font-size: 324pt !important;
  }
  .gb-font-402 {
    font-size: 325pt !important;
  }
  .gb-font-403 {
    font-size: 326pt !important;
  }
  .gb-font-404 {
    font-size: 326pt !important;
  }
  .gb-font-405 {
    font-size: 327pt !important;
  }
  .gb-font-406 {
    font-size: 328pt !important;
  }
  .gb-font-407 {
    font-size: 329pt !important;
  }
  .gb-font-408 {
    font-size: 330pt !important;
  }
  .gb-font-409 {
    font-size: 330pt !important;
  }
  .gb-font-410 {
    font-size: 331pt !important;
  }
  .gb-font-411 {
    font-size: 332pt !important;
  }
  .gb-font-412 {
    font-size: 333pt !important;
  }
  .gb-font-413 {
    font-size: 334pt !important;
  }
  .gb-font-414 {
    font-size: 334pt !important;
  }
  .gb-font-415 {
    font-size: 335pt !important;
  }
  .gb-font-416 {
    font-size: 336pt !important;
  }
  .gb-font-417 {
    font-size: 337pt !important;
  }
  .gb-font-418 {
    font-size: 338pt !important;
  }
  .gb-font-419 {
    font-size: 338pt !important;
  }
  .gb-font-420 {
    font-size: 339pt !important;
  }
  .gb-font-421 {
    font-size: 340pt !important;
  }
  .gb-font-422 {
    font-size: 341pt !important;
  }
  .gb-font-423 {
    font-size: 342pt !important;
  }
  .gb-font-424 {
    font-size: 342pt !important;
  }
  .gb-font-425 {
    font-size: 343pt !important;
  }
  .gb-font-426 {
    font-size: 344pt !important;
  }
  .gb-font-427 {
    font-size: 345pt !important;
  }
  .gb-font-428 {
    font-size: 346pt !important;
  }
  .gb-font-429 {
    font-size: 346pt !important;
  }
  .gb-font-430 {
    font-size: 347pt !important;
  }
  .gb-font-431 {
    font-size: 348pt !important;
  }
  .gb-font-432 {
    font-size: 349pt !important;
  }
  .gb-font-433 {
    font-size: 350pt !important;
  }
  .gb-font-434 {
    font-size: 350pt !important;
  }
  .gb-font-435 {
    font-size: 351pt !important;
  }
  .gb-font-436 {
    font-size: 352pt !important;
  }
  .gb-font-437 {
    font-size: 353pt !important;
  }
  .gb-font-438 {
    font-size: 354pt !important;
  }
  .gb-font-439 {
    font-size: 354pt !important;
  }
  .gb-font-440 {
    font-size: 355pt !important;
  }
  .gb-font-441 {
    font-size: 356pt !important;
  }
  .gb-font-442 {
    font-size: 357pt !important;
  }
  .gb-font-443 {
    font-size: 358pt !important;
  }
  .gb-font-444 {
    font-size: 358pt !important;
  }
  .gb-font-445 {
    font-size: 359pt !important;
  }
  .gb-font-446 {
    font-size: 360pt !important;
  }
  .gb-font-447 {
    font-size: 361pt !important;
  }
  .gb-font-448 {
    font-size: 362pt !important;
  }
  .gb-font-449 {
    font-size: 362pt !important;
  }
  .gb-font-450 {
    font-size: 363pt !important;
  }
  .gb-font-451 {
    font-size: 364pt !important;
  }
  .gb-font-452 {
    font-size: 365pt !important;
  }
  .gb-font-453 {
    font-size: 366pt !important;
  }
  .gb-font-454 {
    font-size: 366pt !important;
  }
  .gb-font-455 {
    font-size: 367pt !important;
  }
  .gb-font-456 {
    font-size: 368pt !important;
  }
  .gb-font-457 {
    font-size: 369pt !important;
  }
  .gb-font-458 {
    font-size: 370pt !important;
  }
  .gb-font-459 {
    font-size: 370pt !important;
  }
  .gb-font-460 {
    font-size: 371pt !important;
  }
  .gb-font-461 {
    font-size: 372pt !important;
  }
  .gb-font-462 {
    font-size: 373pt !important;
  }
  .gb-font-463 {
    font-size: 374pt !important;
  }
  .gb-font-464 {
    font-size: 374pt !important;
  }
  .gb-font-465 {
    font-size: 375pt !important;
  }
  .gb-font-466 {
    font-size: 376pt !important;
  }
  .gb-font-467 {
    font-size: 377pt !important;
  }
  .gb-font-468 {
    font-size: 378pt !important;
  }
  .gb-font-469 {
    font-size: 378pt !important;
  }
  .gb-font-470 {
    font-size: 379pt !important;
  }
  .gb-font-471 {
    font-size: 380pt !important;
  }
  .gb-font-472 {
    font-size: 381pt !important;
  }
  .gb-font-473 {
    font-size: 382pt !important;
  }
  .gb-font-474 {
    font-size: 382pt !important;
  }
  .gb-font-475 {
    font-size: 383pt !important;
  }
  .gb-font-476 {
    font-size: 384pt !important;
  }
  .gb-font-477 {
    font-size: 385pt !important;
  }
  .gb-font-478 {
    font-size: 386pt !important;
  }
  .gb-font-479 {
    font-size: 386pt !important;
  }
  .gb-font-480 {
    font-size: 387pt !important;
  }
  .gb-font-481 {
    font-size: 388pt !important;
  }
  .gb-font-482 {
    font-size: 389pt !important;
  }
  .gb-font-483 {
    font-size: 390pt !important;
  }
  .gb-font-484 {
    font-size: 390pt !important;
  }
  .gb-font-485 {
    font-size: 391pt !important;
  }
  .gb-font-486 {
    font-size: 392pt !important;
  }
  .gb-font-487 {
    font-size: 393pt !important;
  }
  .gb-font-488 {
    font-size: 394pt !important;
  }
  .gb-font-489 {
    font-size: 394pt !important;
  }
  .gb-font-490 {
    font-size: 395pt !important;
  }
  .gb-font-491 {
    font-size: 396pt !important;
  }
  .gb-font-492 {
    font-size: 397pt !important;
  }
  .gb-font-493 {
    font-size: 398pt !important;
  }
  .gb-font-494 {
    font-size: 398pt !important;
  }
  .gb-font-495 {
    font-size: 399pt !important;
  }
  .gb-font-496 {
    font-size: 400pt !important;
  }
  .gb-font-497 {
    font-size: 401pt !important;
  }
  .gb-font-498 {
    font-size: 402pt !important;
  }
  .gb-font-499 {
    font-size: 402pt !important;
  }
  .gb-font-500 {
    font-size: 403pt !important;
  }
}
