.sTcartOuterWrap {
  user-select: none;
  padding-bottom: 3em;
}
.sTcartHeader {
  margin-left: 2.205em;
  display: flex;
  flex-basis: 2.25em; /* IN LINE WITH GLOBAL */
  flex-shrink: 0;
  flex-grow: 0;
  visibility: hidden;
  z-index: -100;
}

.sTcartContents2 {
  height: calc(100% - 4em); /* 4em BECAUSE 1em for margin AND 3em FOR FOOTER */
  overflow: hidden;
  margin: 0.5em auto;
  width: 42.1em;
  max-width: 100%;
  overflow-y: auto;
}
.sTcartContents2b {
  /* height: 100%; */
  margin: auto;
  width: 100%;
  overflow-y: auto;
}
.sTcartFooterWrap {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3em; /* ALIGNED WITH FOOTER IN GLOBAL AND ALSO DRIVING SOME ELEMENTS HERE */
  user-select: none;
}
.sTcartFooter {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.sTcartFooterItem {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  flex-basis: 50%;
  flex-shrink: 0;
  flex-grow: 0;
}
